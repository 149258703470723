// eslint-disable-next-line import/no-unresolved
import produce from "immer"
import filter from "lodash/filter"
import get from "lodash/get"
import moment from "moment"
import isEmpty from "lodash/isEmpty"
import findIndex from "lodash/findIndex"
import includes from "lodash/includes"
// import { CLEAR_ERROR } from "containers/errors/constants"

import { checkSum } from "utils/check-sum"
import { E_SIGN_ERROR_MESSAGE } from "constants/error-messages"
import { findSignerByRecipient, mapFilesForSign } from "helpers/signing"

import { TYPES } from "containers/drag-n-drop/constants"
import { REQUEST_STATUS_TYPE } from "redux-middleware/constants"
import {
  ENVELOPE_REQUEST,
  ENVELOPE_SUCCESS,
  ENVELOPE_FAILURE,
  SHARE_ENVELOPE_REQUEST,
  SHARE_ENVELOPE_SUCCESS,
  SHARE_ENVELOPE_FAILED,
  SIGN_SIGNATURE_SAVE,
  SIGN_SIGNATURE_EDIT,
  UPDATE_ENVELOPE_SIGN_REQUEST,
  UPDATE_ENVELOPE_SIGN_SUCCESS,
  UPDATE_ENVELOPE_SIGN_FAILED,
  UPLOAD_SIGNATURE_REQUEST,
  UPLOAD_SIGNATURE_SUCCESS,
  UPLOAD_SIGNATURE_FAILED,
  SAVE_FILES_ENVELOPE_REQUEST,
  SAVE_FILES_ENVELOPE_SUCCESS,
  SAVE_FILES_ENVELOPE_FAILED,
  REMOVE_ACTIVE_BOX,
  UPDATE_ENVELOPE_CONSENT_REQUEST,
  UPDATE_ENVELOPE_CONSENT_SUCCESS,
  UPDATE_ENVELOPE_CONSENT_FAILED,
  SET_RECIPIENT_ID_SELECTED,
  UPDATE_ENVELOPE_TEMPLATE_REQUEST,
  UPDATE_ENVELOPE_TEMPLATE_FAILED,
  UPDATE_LEASE_AGREEMENT_CONSENT_FAILED,
  UPDATE_LEASE_AGREEMENT_CONSENT_REQUEST,
  UPDATE_LEASE_AGREEMENT_CONSENT_SUCCESS,
  SHOW_CONSENT_MODAL,
  CLEAR_LEASE_ERROR,
  MAP_COMPONENT,
  SIGN_TYPE,
  TYPES_COMPONENT,
  PREPARE_ENVELOPE_REQUEST,
  PREPARE_ENVELOPE_SUCCESS,
  PREPARE_ENVELOPE_FAILED,
  GENERATE_ENVELOPE_PDF_SUCCESS,
  GENERATE_ENVELOPE_PDF_FAILED,
  GENERATE_ENVELOPE_PDF_REQUEST,
  DATE_FIELD_FORMAT,
  TIME_FIELD_FORMAT,
  GENERATE_PDF_FILE_URL_REQUEST,
  GENERATE_PDF_FILE_URL_SUCCESS,
  GENERATE_PDF_FILE_URL_FAILED,
  DATE_SIGNED_SAVE,
  HANDLE_AFTER_ENVELOPE_SIGN_REQUEST,
  HANDLE_AFTER_ENVELOPE_SIGN_SUCCESS,
  HANDLE_AFTER_ENVELOPE_SIGN_FAILED,
} from "./constants"
import { getPageSplit } from "./helpers/page-split"

export const initialState = {
  isLoadingGetEnvelope: false,
  isLoadingUpdateEnvelope: false,
  isLoadingShareEnvelope: false,
  isLoadingPrepareEnvelope: false,
  isUploadingSignature: false,
  isLoadingSavingFile: false,
  isLoadingUpdateConsent: false,
  isLoadingGenerateEnvelopePDF: false,
  updateConsentSuccess: false,
  isError: false,
  envelopeData: {},
  error: null,
  saveFileLoadings: [],
  recipientIdSelected: "",
  isAccepting: false,
  isConsentSuccess: false,
  isShowConsentModal: false,
  uploadSignatureSuccess: false,
  isSignatureEdited: false,
  isSavedInformation: false,
  isLoadingGeneratePdfFileURL: false,
  afterSignStatus: REQUEST_STATUS_TYPE.INITIAL,
}

export const findInsertionIndex = (array, element, compareFunc) => {
  let left = 0
  let right = array.length - 1
  while (left <= right) {
    // eslint-disable-next-line no-bitwise
    const mid = (left + right) >>> 1
    const cmp = compareFunc(element, array[mid])
    if (cmp > 0) {
      left = mid + 1
    } else {
      right = mid - 1
    }
  }
  return left
}

export const compareBox = (a, b) => {
  const THRESHOLD = 4
  const HEIGHT = 16

  // TODO
  // for now the sorting is calculated by the lower bound,
  // which p'mick has suggested it should have been the upper bound (top)
  // so the solution is to change from aLowerBound, bLowerBound to a.top, b.top
  const aLowerBound = a.top + get(a.styles, "height", HEIGHT)
  const bLowerBound = b.top + get(b.styles, "height", HEIGHT)
  if (Math.abs(aLowerBound - bLowerBound) <= THRESHOLD) {
    return a.left - b.left
  }
  return aLowerBound - bLowerBound
}

export const modifyItemValue = ({ item, recipients }) => {
  const { roleId } = findSignerByRecipient(recipients)
  const { value, assignee, format } = item

  if (!assignee || assignee === "unassigned") return value

  const isAssignee = roleId === assignee
  // TODO: [craf-2955] Deprecated 12 2022 LRA will use DATE_SIGNS (reserve code for LRA 12 2019)
  switch (format) {
    case DATE_FIELD_FORMAT:
      if (isAssignee) return moment().format("MM/DD/YYYY")
      return value
    case TIME_FIELD_FORMAT:
      if (isAssignee) return moment().format("hh:mm A")
      return value
    default:
      return value
  }
}

export const modifyItemData = (i, j, item, recipients) => {
  const index = findIndex(
    recipients,
    recipient =>
      get(recipient, "_id") === item.assignee ||
      get(recipient, "roleId") === item.assignee,
  )

  const modifiedItem = {
    ...item,
    page: `${i},${j}`,
    element: MAP_COMPONENT[item.type],
    ...(item.format && {
      value: modifyItemValue({ item, recipients }),
    }),
  }

  if (
    includes(
      [
        TYPES_COMPONENT.TEXT_BOX,
        TYPES_COMPONENT.SIGNATURE_SIGNS,
        TYPES_COMPONENT.INITIAL_SIGNS,
        TYPES_COMPONENT.DATE_SIGNS,
      ],
      item.type,
    )
  ) {
    return {
      ...modifiedItem,
      assignee: item.assignee,
      colorIndex: index,
    }
  }

  return modifiedItem
}

export const modifyEnvelopeData = payload => ({
  ...payload,
  files: payload.files.map((file, i) => ({
    ...file,
    pages: file.pages.map((page, j) => ({
      ...page,
      fields: page.fields.map(item =>
        modifyItemData(i, j, item, payload.recipients),
      ),
    })),
  })),
})

export const getPage = (envelopeData, pageNumber) => {
  const { files } = envelopeData
  const { file, page } = getPageSplit(pageNumber)
  return files[file].pages[page]
}

export const removeLoadingEnvelope = (loadingList, envelopeId) =>
  loadingList.filter(envelope => envelope.id !== envelopeId)

/* eslint-disable default-case, no-param-reassign */
const envelopeReducer = (state = initialState, { type, payload, ...rest }) =>
  produce(state, draft => {
    switch (type) {
      case UPDATE_ENVELOPE_CONSENT_REQUEST:
        draft.isLoadingUpdateConsent = true
        draft.error = null
        break
      case UPDATE_ENVELOPE_CONSENT_SUCCESS:
        draft.isLoadingUpdateConsent = false
        draft.updateConsentSuccess = true
        break
      case UPDATE_ENVELOPE_CONSENT_FAILED:
        draft.isLoadingUpdateConsent = false
        draft.updateConsentSuccess = false
        draft.error = {
          errorMessage: E_SIGN_ERROR_MESSAGE.CONSENT_ERROR.TITLE,
          secondaryMessage: E_SIGN_ERROR_MESSAGE.CONSENT_ERROR.MESSAGE,
        }
        break
      // Envelope Data reducer
      case SAVE_FILES_ENVELOPE_REQUEST:
        draft.isLoadingSavingFile = true
        draft.saveFileLoadings.push({ id: rest[0] })
        break
      case SAVE_FILES_ENVELOPE_SUCCESS:
      case SAVE_FILES_ENVELOPE_FAILED:
        draft.isLoadingSavingFile = false
        draft.saveFileLoadings = removeLoadingEnvelope(
          draft.saveFileLoadings,
          payload.envelopeId,
        )
        break
      // Get pdf file as url object reducer
      case GENERATE_PDF_FILE_URL_REQUEST:
        draft.isLoadingGeneratePdfFileURL = true
        break
      case GENERATE_PDF_FILE_URL_SUCCESS:
        draft.isLoadingGeneratePdfFileURL = false
        break
      case GENERATE_PDF_FILE_URL_FAILED:
        draft.isLoadingGeneratePdfFileURL = false
        draft.error = payload.error
        break
      // Rental document Consent
      case UPDATE_LEASE_AGREEMENT_CONSENT_REQUEST: {
        draft.isAccepting = true
        break
      }
      case UPDATE_LEASE_AGREEMENT_CONSENT_SUCCESS: {
        draft.isAccepting = false
        draft.isConsentSuccess = payload.isConsentSuccess
        draft.isShowConsentModal = payload.isShowConsentModal
        break
      }
      case UPDATE_LEASE_AGREEMENT_CONSENT_FAILED: {
        draft.isAccepting = false
        draft.error = {
          errorMessage: E_SIGN_ERROR_MESSAGE.CONSENT_ERROR.TITLE,
          secondaryMessage: E_SIGN_ERROR_MESSAGE.CONSENT_ERROR.MESSAGE,
        }
        break
      }
      case SHOW_CONSENT_MODAL: {
        draft.error = null
        draft.isShowConsentModal = payload
        break
      }
      case CLEAR_LEASE_ERROR: {
        draft.error = null
        draft.isConsentSuccess = false
        break
      }
      // #region
      case ENVELOPE_REQUEST:
        draft.isLoadingGetEnvelope = true
        draft.isError = false
        draft.error = null
        break
      case ENVELOPE_SUCCESS:
        draft.isLoadingGetEnvelope = false
        draft.isError = false
        draft.envelopeData = !isEmpty(payload)
          ? modifyEnvelopeData(payload)
          : {}
        break
      case ENVELOPE_FAILURE:
        draft.isLoadingGetEnvelope = false
        draft.isError = true
        break
      case UPDATE_ENVELOPE_TEMPLATE_REQUEST:
      case UPDATE_ENVELOPE_SIGN_REQUEST:
        draft.isLoadingUpdateEnvelope = true
        draft.isError = false
        draft.error = null
        break
      case UPDATE_ENVELOPE_SIGN_SUCCESS:
        draft.isLoadingUpdateEnvelope = false
        draft.isError = false
        draft.recipientIdSelected = ""
        draft.isSavedInformation = true
        break
      case UPDATE_ENVELOPE_TEMPLATE_FAILED:
      case UPDATE_ENVELOPE_SIGN_FAILED:
        draft.isLoadingUpdateEnvelope = false
        draft.isError = true
        draft.recipientIdSelected = ""
        draft.error = payload
        break
      case HANDLE_AFTER_ENVELOPE_SIGN_REQUEST:
        draft.afterSignStatus = REQUEST_STATUS_TYPE.PENDING
        draft.isError = false
        draft.error = null
        break
      case HANDLE_AFTER_ENVELOPE_SIGN_SUCCESS:
        draft.afterSignStatus = REQUEST_STATUS_TYPE.FULFILLED
        draft.isError = false
        break
      case HANDLE_AFTER_ENVELOPE_SIGN_FAILED:
        draft.afterSignStatus = REQUEST_STATUS_TYPE.REJECTED
        draft.isError = true
        draft.error = payload
        break
      case SHARE_ENVELOPE_REQUEST:
        draft.isLoadingShareEnvelope = true
        draft.isError = false
        draft.error = null
        break
      case SHARE_ENVELOPE_SUCCESS:
        draft.isLoadingShareEnvelope = false
        draft.isError = false
        break
      case SHARE_ENVELOPE_FAILED:
        draft.isLoadingShareEnvelope = false
        draft.isError = true
        draft.error = payload
        break
      case UPLOAD_SIGNATURE_REQUEST:
        draft.isUploadingSignature = true
        draft.isError = false
        draft.uploadSignatureSuccess = false
        draft.error = null
        break
      case UPLOAD_SIGNATURE_SUCCESS:
        draft.isUploadingSignature = false
        draft.uploadSignatureSuccess = true
        draft.isError = false
        break
      case UPLOAD_SIGNATURE_FAILED:
        draft.isUploadingSignature = false
        draft.isError = true
        draft.error = payload
        break
      case TYPES.SET_CHILDREN_BOX: {
        const getPageData = getPage(draft.envelopeData, payload.page)

        // find and change box
        let indexToChange = 0
        for (; indexToChange < getPageData.fields.length; indexToChange += 1) {
          if (
            getPageData.fields[indexToChange].fieldId === payload.box.fieldId
          ) {
            getPageData.fields[indexToChange] = payload.box
            break
          }
        }

        // almost sorted array, so no need to do full sort
        // swap nearby box until in correct places
        // compare with the left side
        for (
          let checkIndex = indexToChange - 1;
          checkIndex >= 0 &&
          compareBox(
            getPageData.fields[indexToChange],
            getPageData.fields[checkIndex],
          ) < 0;
          checkIndex -= 1
        ) {
          // swap
          const temp = getPageData.fields[indexToChange]
          getPageData.fields[indexToChange] = getPageData.fields[checkIndex]
          getPageData.fields[checkIndex] = temp

          // update the index accordingly
          indexToChange = checkIndex
        }

        // compare with the right side
        for (
          let checkIndex = indexToChange + 1;
          checkIndex < getPageData.fields.length &&
          compareBox(
            getPageData.fields[indexToChange],
            getPageData.fields[checkIndex],
          ) > 0;
          checkIndex += 1
        ) {
          // swap
          const temp = getPageData.fields[indexToChange]
          getPageData.fields[indexToChange] = getPageData.fields[checkIndex]
          getPageData.fields[checkIndex] = temp

          // update the index accordingly
          indexToChange = checkIndex
        }

        draft.activeId = {
          ...payload.box,
        }
        break
      }
      case TYPES.SET_ACTIVE_ID: {
        draft.activeId = { ...payload }
        break
      }
      case TYPES.RESET_ACTIVE:
        draft.activeId = {}
        break
      case REMOVE_ACTIVE_BOX: {
        if (!isEmpty(draft.activeId) && !draft.activeId.undeletable) {
          const getPageData = getPage(draft.envelopeData, draft.activeId.page)
          getPageData.fields = filter(
            getPageData.fields,
            item => item.fieldId !== draft.activeId.fieldId,
          )
          draft.activeId = {}
          draft.toolbar = []
        }
        break
      }
      case SIGN_SIGNATURE_SAVE: {
        draft.signature = get(payload, "signatureData", {})
        draft.activeId = get(payload, "activeId", draft.activeId)
        const getPageData = getPage(draft.envelopeData, draft.activeId.page)
        const date = moment().format("MM/DD/YYYY")
        getPageData.fields = getPageData.fields.map(item => {
          if (item.fieldId === draft.activeId.fieldId) {
            const signatureType = draft.signature?.type
            if (draft.activeId.type === TYPES_COMPONENT.SIGNATURE_SIGNS) {
              const signature = draft.signature?.fullName
              const c = checkSum(
                signature + draft.activeId.left + draft.activeId.top + date,
              )
              return {
                ...draft.activeId,
                value: signature,
                checkSum: c,
                signatureType,
              }
            }
            if (draft.activeId.type === TYPES_COMPONENT.INITIAL_SIGNS) {
              const initialSignature = draft.signature?.initials
              const c = checkSum(
                initialSignature +
                  draft.activeId.left +
                  draft.activeId.top +
                  date,
              )
              return {
                ...draft.activeId,
                value: initialSignature,
                signatureType,
                checkSum: c,
              }
            }
            return draft.activeId
          }
          return item
        })
        draft.activeId = {}
        break
      }
      case DATE_SIGNED_SAVE: {
        const getPageData = getPage(draft.envelopeData, draft.activeId.page)
        getPageData.fields = getPageData.fields.map(item => {
          if (item.fieldId === draft.activeId.fieldId) {
            if (draft.activeId.type === TYPES_COMPONENT.DATE_SIGNS) {
              return {
                ...draft.activeId,
                value: payload.dateSigned,
                timeZone: payload.timeZone,
              }
            }
            return draft.activeId
          }
          return item
        })
        draft.activeId = {}
        draft.toolbar = []
        break
      }
      case SIGN_SIGNATURE_EDIT: {
        draft.signature = get(payload, "signatureData", {})
        const newFile = draft.envelopeData.files.map(files => ({
          ...files,
          pages: files.pages.map(pages => ({
            ...pages,
            fields: pages.fields.map(item => {
              const isActionType = SIGN_TYPE.includes(item.type)
              if (isActionType) {
                if (item.canEdit) {
                  let value
                  let checkSumData = null
                  const date = moment().format("MM/DD/YYYY")
                  const signatureType = draft.signature.type
                  if (item.type === TYPES_COMPONENT.INITIAL_SIGNS) {
                    const initialSignature = draft.signature?.initials
                    checkSumData = checkSum(
                      initialSignature + item.left + item.top + date,
                    )
                    value = initialSignature
                  } else if (item.type === TYPES_COMPONENT.SIGNATURE_SIGNS) {
                    const signature = draft.signature?.fullName
                    checkSumData = checkSum(
                      signature + item.left + item.top + date,
                    )
                    value = signature
                  }
                  return {
                    ...item,
                    value,
                    signatureType,
                    checkSum: checkSumData,
                  }
                }
                if (item.value) {
                  return item
                }
              }
              return item
            }),
          })),
        }))
        draft.activeId = {}
        draft.envelopeData.files = newFile
        draft.isSignatureEdited = true
        break
      }
      case SET_RECIPIENT_ID_SELECTED: {
        draft.recipientIdSelected = payload
        break
      }
      case PREPARE_ENVELOPE_FAILED: {
        draft.isLoadingPrepareEnvelope = false
        draft.isError = true
        break
      }
      case PREPARE_ENVELOPE_SUCCESS: {
        draft.isLoadingPrepareEnvelope = false
        draft.isError = false
        if (!isEmpty(payload)) {
          const { recipients, files } = payload
          const signer = findSignerByRecipient(recipients)
          const mappedFiles = mapFilesForSign(files, signer)
          draft.envelopeData = modifyEnvelopeData({
            ...payload,
            files: mappedFiles,
          })
        } else draft.envelopeData = {}
        break
      }
      case PREPARE_ENVELOPE_REQUEST:
        draft.isLoadingPrepareEnvelope = true
        draft.isError = false
        draft.error = null
        break
      case GENERATE_ENVELOPE_PDF_FAILED: {
        draft.isLoadingGenerateEnvelopePDF = false
        draft.isError = true
        break
      }
      case GENERATE_ENVELOPE_PDF_SUCCESS: {
        draft.isLoadingGenerateEnvelopePDF = false
        draft.isError = false
        draft.error = null
        draft.envelopeData.pdfPath = payload.pdfPath
        break
      }
      case GENERATE_ENVELOPE_PDF_REQUEST: {
        draft.isLoadingGenerateEnvelopePDF = true
        draft.isError = false
        draft.error = null
        break
      }
    }
  })

export default envelopeReducer
