import generateApiCall from "utils/generate-api-call"

import {
  callSaveAllField,
  getProfileCall,
  profileClear,
  callRedirectRentalSubmission,
  callSetSuccess,
  callRedirectAuthWeb,
  getScreeningRequestCall,
  VERIFY_MULTI_SHARE_CALL,
  VERIFY_MULTI_SHARE_REQUEST,
  VERIFY_MULTI_SHARE_SUCCESS,
  VERIFY_MULTI_SHARE_FAILED,
  callRedirectRentalApp,
} from "./constants"

export function getProfile() {
  return getProfileCall()
}

export function saveAllField(payload) {
  return callSaveAllField(payload)
}

export function clearProfile() {
  return profileClear()
}

export function redirectRentalApp(destination) {
  return callRedirectRentalApp(destination)
}

export function redirect(url, isReplace, search) {
  return callRedirectRentalSubmission(url, isReplace, search)
}

export function setSuccess() {
  return callSetSuccess()
}

export function redirectAuthWeb(...args) {
  return callRedirectAuthWeb(...args)
}

export function getScreeningRequest(screeningRequestId) {
  return getScreeningRequestCall({ screeningRequestId })
}

export const verifyMultiShareCall = () => ({
  type: VERIFY_MULTI_SHARE_CALL,
})

export const verifyMultiShareApiActions = generateApiCall([
  VERIFY_MULTI_SHARE_REQUEST,
  VERIFY_MULTI_SHARE_SUCCESS,
  VERIFY_MULTI_SHARE_FAILED,
])
