export const FULL_LOADING = "FULL_LOADING"
export const HIDE_LOADING = "HIDE_LOADING"
export const SHOW_COMPONENTS_LOADING = "SHOW_COMPONENTS_LOADING"

export const callHideLoading = () => ({ type: HIDE_LOADING })
export const componentLoading = () => ({
  type: SHOW_COMPONENTS_LOADING,
})
export const callFullLoading = () => ({
  type: FULL_LOADING,
})
