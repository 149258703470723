import { useContext } from "react"
import { useAuth0 } from "@auth0/auth0-react"

import logger from "helpers/logger"
import AuthContext from "../providers/auth.context"
import { ROUTE } from "../containers/router/constants"

const useAuth = () => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider")
  }
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()

  const getAccessToken = async () => {
    try {
      return await getAccessTokenSilently()
    } catch (error) {
      if (error.error === "invalid_grant") {
        logger.info(error)
      } else {
        logger.error(error)
      }
      window.location.replace(
        `${window.location.origin}${ROUTE.SESSION_TIMEOUT}`,
      )
      return null
    }
  }

  return {
    isAuthenticated,
    isLoading,
    getAccessToken,
  }
}

export default useAuth
