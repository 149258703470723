/* eslint-disable no-underscore-dangle */
import { buildPath } from "@rentspree/path"
import * as Sentry from "@sentry/browser"
import {
  call,
  put,
  select,
  takeLatest,
  delay,
  all,
  spawn,
  race,
  cancel,
} from "redux-saga/effects"
import { agentReviewApiInstance, apiInstance } from "utils/api-interceptor"
import { redirect } from "containers/wrapper/actions"
import { tracker } from "tracker"
import { PAYMENT_EVENT } from "tracker/tracker-const"
import {
  CreatePublicContactError,
  SubmitRentalSubmissionError,
} from "utils/errors"
import { SKIP_PAYMENT_PROCESS_API_URL } from "containers/tu-screening/constants"
import SweetAlert from "components/sweetalert"
import { ALERT_PRESET, API_ERRORS } from "components/sweetalert/constants"
import { locationAssign } from "utils/call-window"
import { callFullLoading, callHideLoading } from "containers/loading/constants"
import { getProperty } from "containers/property/selectors"
import { CancelToken } from "axios"

import { ROUTE } from "../../router/constants"
import {
  SUBMIT_RENTAL_CALL,
  SUBMIT_RENTAL_API_URL,
  PREPARE_ENVELOPE_TIMEOUT,
  CREATE_PUBLIC_CONTACT_API,
  CREATE_PUBLIC_CONTACT_CALL,
  postRentalSubmissionRequest,
  postRentalSubmissionSuccess,
  postRentalSubmissionFailed,
  postPublicContactRequest,
  postPublicContactSuccess,
  postPublicContactFailed,
  TRACKING_EVENT_METHOD_NAME,
  CREATE_AGENT_REVIEW_PLACEHOLDER_TIMEOUT,
} from "./constants"
import {
  putPrepareEnvelopeRequest,
  putPrepareEnvelopeSuccess,
  putPrepareEnvelopeFailure,
} from "../../envelope/actions"
import { PREPARE_ENVELOPE_CALL } from "../../envelope/constants"
import { PREPARE_ENVELOPE_API_URL } from "../../envelope/api-constants"
import {
  selectLraEnvelopeId,
  getRental,
  selectRentalId,
  makeSelectCreditReport,
} from "../selectors"
import { parseRentalSubmissionToContact } from "./helper"
import { getApplicationApi } from "../../../containers/application/saga"
import { makeSelectMultiShare } from "../../wrapper/selectors"

export const submitRentalApi = rentalId =>
  apiInstance.post(buildPath(SUBMIT_RENTAL_API_URL, { rentalId }))

export const prepareEnvelopeApi = ({ rentalId, useFasterProcessing }) =>
  apiInstance.put(buildPath(PREPARE_ENVELOPE_API_URL, { rentalId }), {
    useFasterProcessing,
  })

export const createPublicContactApi = rentalSubmission =>
  apiInstance.post(buildPath(CREATE_PUBLIC_CONTACT_API), {
    ...parseRentalSubmissionToContact(rentalSubmission),
  })

export const createAgentReviewPlaceholderApi = async (
  data,
  cancelTokenSource = CancelToken.source(),
) => {
  // Set a timeout to make sure that creating an agent review after the renter submits the report is non-blocking.
  const timeout = setTimeout(() => {
    cancelTokenSource.cancel()
    console.error(
      `Create agent review placeholder API canceled due to exceeding the timeout of ${CREATE_AGENT_REVIEW_PLACEHOLDER_TIMEOUT}ms`,
    )
  }, CREATE_AGENT_REVIEW_PLACEHOLDER_TIMEOUT)

  try {
    await agentReviewApiInstance.post(buildPath(`/placeholder`), data, {
      cancelToken: cancelTokenSource.token,
      timeout: CREATE_AGENT_REVIEW_PLACEHOLDER_TIMEOUT,
    })
  } catch (error) {
    console.error("[Error] Cannot create agent review placeholder", error)
  } finally {
    clearTimeout(timeout)
  }
}

export const getAgentReviewByRentalApi = rentalSubmissionId =>
  agentReviewApiInstance.get(buildPath(`/submission/${rentalSubmissionId}`))

// TODO: Clean up: `centralized_sign_page`, consider remove this function
export function* spawnPrepareEnvelope({ rentalId, useFasterProcessing }) {
  yield put(putPrepareEnvelopeRequest())
  try {
    const [response] = yield race([
      call(prepareEnvelopeApi, { rentalId, useFasterProcessing }),
      delay(PREPARE_ENVELOPE_TIMEOUT, true),
    ])
    if (response) {
      yield put(putPrepareEnvelopeSuccess(response))
    } else {
      yield call([window.location, window.location.reload])
    }
  } catch (err) {
    yield put(putPrepareEnvelopeFailure(err))
  }
}

export function* prepareEnvelope({ payload }) {
  // TODO: Clean up: remove `centralized_sign_page` flag usage and use new logic instead
  const {
    useFasterProcessing,
    screeningRequestId,
    isCentralizedSignPageGateEnabled,
  } = payload
  if (isCentralizedSignPageGateEnabled) {
    try {
      yield put(callFullLoading())
      yield put(putPrepareEnvelopeRequest())
      const rental = yield select(getRental)
      const envelopeId = yield select(selectLraEnvelopeId)
      const { id: rentalId, application } = rental
      const property = yield select(getProperty)

      const applicationId =
        typeof application === "object" && application !== null
          ? application._id
          : application

      yield call(prepareEnvelopeApi, { rentalId, useFasterProcessing })
      const continuePath = `/apply/${screeningRequestId}${buildPath(
        ROUTE.ENVELOPE.AFTER_SIGN,
        {
          envelopeId,
        },
      )}`
      const esignSignPagePath = buildPath(
        ROUTE.ESIGN.RENTER_SIGN_PAGE,
        {
          propertyId: property._id,
          rentalId,
          applicationId,
        },
        {
          continuePath,
        },
      )
      yield put(putPrepareEnvelopeSuccess())
      yield call([window.location, window.location.assign], esignSignPagePath)
    } catch (err) {
      yield put(callHideLoading())
      yield put(putPrepareEnvelopeFailure(err))
    }
  } else {
    const rentalId = yield select(selectRentalId)
    const envelopeId = yield select(selectLraEnvelopeId)
    const envelopePath = buildPath(ROUTE.ENVELOPE.SIGN, {
      envelopeId,
    })
    yield spawn(spawnPrepareEnvelope, { rentalId, useFasterProcessing })
    yield put(redirect(envelopePath))
  }
}

export const skipPaymentProcess = rentalId =>
  apiInstance.post(buildPath(SKIP_PAYMENT_PROCESS_API_URL, { rentalId }), {})

export function* postSubmitRental({ payload = {} } = {}) {
  const { isNextToDocumentStep } = payload
  const rentalId = yield select(selectRentalId)
  const rentalDetail = yield select(getRental)
  const { screeningOption } = rentalDetail
  const creditReport = yield select(makeSelectCreditReport())
  const multiShare = yield select(makeSelectMultiShare())
  const { isMultiShareActive: isEnableMultiShare } = multiShare
  const shouldSkipPayment = creditReport && isEnableMultiShare
  const { isRenterViewReportFirst } = yield select(getRental)

  yield put(postRentalSubmissionRequest())
  try {
    if (shouldSkipPayment) {
      yield call(skipPaymentProcess, rentalId)
    }
    if (isRenterViewReportFirst) {
      const destinationPath = buildPath(ROUTE.DASHBOARD_AGENT_REPORT, {
        rentalId,
      })
      yield call(locationAssign, destinationPath)
      return
    }

    if (isNextToDocumentStep) {
      if (screeningOption?.income) {
        yield put(redirect(ROUTE.INCOME_VERIFICATION, true))
        return
      }
      yield put(redirect(ROUTE.DOCUMENT_UPLOAD, true))
      return
    }
    const response = yield call(submitRentalApi, rentalId)
    yield call(
      [tracker, TRACKING_EVENT_METHOD_NAME],
      PAYMENT_EVENT.submitSuccess,
    )
    yield delay(200)

    const createAgentReviewPlaceholderPayload = {
      // eslint-disable-next-line camelcase
      reviewee: { userId: response?.landlord_id },
      rentalSubmissionId: response?._id,
      createdFrom: "tenant_screening",
    }
    yield call(
      createAgentReviewPlaceholderApi,
      createAgentReviewPlaceholderPayload,
    )
    yield put(postRentalSubmissionSuccess(response))
    yield put(redirect(ROUTE.GUIDE.FINISH, true))
  } catch (err) {
    yield call(
      Sentry.captureException,
      new SubmitRentalSubmissionError(JSON.stringify(err)),
    )
    yield put(postRentalSubmissionFailed(err))
    yield call([tracker, TRACKING_EVENT_METHOD_NAME], PAYMENT_EVENT.submitFail)
    yield call(
      SweetAlert,
      ALERT_PRESET.TRY_AGAIN,
      API_ERRORS[500].option,
      API_ERRORS[500].callback,
    )
  }
}

export function* createPublicContact() {
  const rentalSubmission = yield select(getRental)
  const { application, screeningOption } = rentalSubmission
  let rentalData = { ...rentalSubmission }

  if (
    screeningOption.application &&
    (!application?.residence || !application?.occupation)
  ) {
    const rentalId = yield select(selectRentalId)
    // TODO: [CRAFT] - As this `completeCreditReport` saga function was rendered two times.
    // In first call, rentalId is undefined. So, we have to work-around like this.
    if (!rentalId) {
      yield put(postPublicContactFailed())
      yield cancel()
    }
    const response = yield call(getApplicationApi, rentalId)
    rentalData = { ...rentalData, application: response }
  }

  yield put(postPublicContactRequest())
  try {
    const response = yield call(createPublicContactApi, rentalData)
    yield put(postPublicContactSuccess(response))
  } catch (err) {
    yield call(
      Sentry.captureException,
      new CreatePublicContactError(JSON.stringify(err)),
    )
    yield put(postPublicContactFailed(err))
  }
}

export function* watchPrepareEnvelope() {
  yield takeLatest(PREPARE_ENVELOPE_CALL, prepareEnvelope)
}

export function* watchSubmitRental() {
  yield takeLatest(SUBMIT_RENTAL_CALL, postSubmitRental)
}

export function* watchCreatePublicContact() {
  yield takeLatest(CREATE_PUBLIC_CONTACT_CALL, createPublicContact)
}

export default function* rootSaga() {
  yield all([
    watchSubmitRental(),
    watchPrepareEnvelope(),
    watchCreatePublicContact(),
  ])
}
