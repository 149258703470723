let env
if (
  typeof window !== "undefined" &&
  window.process &&
  window.process.NODE_ENV === "production"
) {
  env = window.process
} else {
  /* eslint-disable prefer-destructuring */
  env = process.env
}
const proc = env
// export default (typeof window !== "undefined" && window.process ? (window.process.NODE_ENV === "production") ? window.process : process.env : process.env || {})
// export default proc

export const NODE_ENV = proc.NODE_ENV
export const MAIN_URL = proc.MAIN_URL
export const RENTSPREE_DEV_SUBDOMAIN = "dev.rentspree.dev"
export const USER_API_URL = proc.USER_API_URL
export const DOMAIN_NAME = proc.DOMAIN_NAME
export const BASE_NAME = proc.BASE_NAME
export const API_URL = proc.API_URL
export const APPLICATION_WEB_V2 = proc.APPLICATION_WEB_V2
export const EMAIL_SUPPORT = proc.EMAIL_SUPPORT
// ----- Tracker ----- //
export const GA_TRACKING_ID = proc.GA_TRACKING_ID
export const GOOGLE_TAG_MANAGER_CONTAINER_ID =
  proc.GOOGLE_TAG_MANAGER_CONTAINER_ID
export const FULL_STORY_ORG = proc.FULL_STORY_ORG
export const AMPLITUDE_API_KEY = proc.AMPLITUDE_API_KEY
export const AMPLITUDE_COOKIE_DOMAIN = proc.AMPLITUDE_COOKIE_DOMAIN
// ----- End Tracker ----- //

// sentry env
export const CI_COMMIT_SHA = proc.CI_COMMIT_SHA
export const SENTRY_DSN = proc.SENTRY_DSN
export const ENVIRONMENT = proc.ENVIRONMENT

// rentals env
export const RENTALS_DOMAIN = proc.RENTALS_DOMAIN
export const RENTALS_DASHBOARD_DOMAIN = proc.RENTALS_DASHBOARD_DOMAIN

// version check env
export const IDLE_TIMEOUT_MS = parseInt(proc.IDLE_TIMEOUT_MS, 10)

// File Generator
export const { FILES_URL = `files.${RENTSPREE_DEV_SUBDOMAIN}` } = proc
export const LRA_RELEASE_DATE = proc.LRA_RELEASE_DATE
export const STRIPE_PUBLIC_KEY = proc.STRIPE_PUBLIC_KEY

// Error Message
export const TU_EXAM_WRONG_SSN = proc.TU_EXAM_WRONG_SSN
export const ERROR_ASK_SUPPORT_TO_UPDATE = proc.ERROR_ASK_SUPPORT_TO_UPDATE
export const TU_CONTACT_KB_URL = proc.TU_CONTACT_KB_URL

// REx env
export const REX_API_URL =
  proc.REX_API_URL || `https://cms-rex.${RENTSPREE_DEV_SUBDOMAIN}`
export const REX_API_TOKEN =
  proc.REX_API_TOKEN ||
  "dd9e6c72416b3e35607bb5dbb83a2f2393ba19f077a05655bfd440bd735d36b60be971de65b2605227cbdba85a9e7e070402e55d559c90181b1aad4b9ec7225b72572e5460cfc7788a1badee263fb3694ace081986fdb8d7b845af31c665ee95989ccf21b9ad3a2c9fdcf1675309c032215643e7a6c18db260e3744ac2655ce1"
export const REX_APPLY_CHECKLIST_ID =
  parseInt(proc.REX_APPLY_CHECKLIST_ID, 10) || 2
export const REX_SUBMIT_APPLICATION_NAME =
  proc.REX_SUBMIT_APPLICATION_NAME || "Submit application"

// agent review env
export const AGENT_REVIEW_API_BASE_URL = proc.AGENT_REVIEW_API_BASE_URL

// feedback collection
export const SURVICATE_SDK_PROVIDER_KEY = proc.SURVICATE_SDK_PROVIDER_KEY

// Statsig
export const STATSIG_SDK_KEY = proc.STATSIG_SDK_KEY
export const STATSIG_API = proc.STATSIG_API
export const STATSIG_ENVIRONMENT = proc.STATSIG_ENVIRONMENT

// Google
export const GOOGLE_PLACE_API_KEY = proc.GOOGLE_PLACE_API_KEY

export const {
  IMAGE_PROXY_URL = "https://storage.googleapis.com",
  LISTING_BUCKET = "rentspree-listing-dev",
  IMAGE_PROXY_HOST = `https://image-proxy.${RENTSPREE_DEV_SUBDOMAIN}`,
  IMAGE_PROXY_RESIZE_HEIGHT = "800",
  IMAGE_PROXY_RESIZE_WIDTH = "1200",
} = proc

// DataDog
export const { DATADOG_APPLICATION_ID } = proc
export const { DATADOG_CLIENT_TOKEN } = proc
export const { DATADOG_SITE } = proc
export const DATADOG_SAMPLE_RATE = parseInt(proc.DATADOG_SAMPLE_RATE, 10)
export const DATADOG_REPLAY_SAMPLE_RATE = parseInt(
  proc.DATADOG_REPLAY_SAMPLE_RATE,
  10,
)
export const DATADOG_TRACE_SAMPLE_RATE = parseInt(
  proc.DATADOG_TRACE_SAMPLE_RATE,
  10,
)

// === Auth0 ===

export const { AUTH_PROVIDER_DOMAIN } = proc
export const { AUTH_APP_CLIENT_ID } = proc
export const { AUTH_API_IDENTIFIER } = proc
export const { AUTH_APP_SCOPE } = proc
