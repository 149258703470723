/* eslint-disable no-underscore-dangle */
import { call, put, select, fork } from "redux-saga/effects"
import { buildPath } from "@rentspree/path"
import _ from "lodash"
import isEmpty from "lodash/isEmpty"
import { apiInstance } from "utils/api-interceptor"
import { parse } from "utils/query-parser"

import { ROUTE } from "containers/router/constants"
import { redirect } from "containers/wrapper/actions"
import { selectRentalId } from "./selectors"
import { getSearch } from "../wrapper/selectors"
import {
  GET_RENTAL_API_URL,
  GET_RENTAL_WITH_CO_APPLICANT_API_URL,
  getRentalSubmissionRequest,
  getRentalSubmissionSuccess,
  getRentalSubmissionFailed,
  getRentalWithCoApplicantRequest,
  getRentalWithCoApplicantSuccess,
  getRentalWithCoApplicantFailed,
  isInstruction,
  RENTAL_STATUS,
} from "./constants"
import { getDraftDocument } from "../document-upload/actions"
import { selectIsResumeAppEnabled } from "../feature-flags/selectors"

export const getRentalSubmissionApi = ({ screeningRequestId }) =>
  apiInstance.get(
    buildPath(GET_RENTAL_API_URL, {
      screeningRequestId,
    }),
  )

export const getRentalWithCoApplicantAPI = ({ rentalId }) =>
  apiInstance.get(
    buildPath(GET_RENTAL_WITH_CO_APPLICANT_API_URL, {
      rentalId,
    }),
  )

export function* getRentalWithCoApplicant() {
  const rentalId = yield select(selectRentalId)
  const isResumeAppEnabled = yield select(selectIsResumeAppEnabled)
  try {
    yield put(getRentalWithCoApplicantRequest())

    const response = yield call(getRentalWithCoApplicantAPI, { rentalId })
    if (!_.get(response, ["screeningOption", "application"], true)) {
      yield put(redirect())
    }
    yield put(getRentalWithCoApplicantSuccess(response, isResumeAppEnabled))
  } catch (err) {
    yield put(getRentalWithCoApplicantFailed(err.message))
  }
}

export function* checkStatusRental(err, rental) {
  const search = yield select(getSearch)
  let { show_agent_review: showAgentReview } = parse(search) || {}
  showAgentReview = !!showAgentReview

  const propertyRequired =
    RENTAL_STATUS.PROPERTY_REQUIRED === _.get(rental, "status")

  const redirectToSubmitted =
    _.get(err, "status") === 409 || (propertyRequired && !showAgentReview)

  if (redirectToSubmitted) {
    yield put(redirect(ROUTE.GUIDE.SUBMITTED))
  } else if (
    isInstruction(_.get(rental, "status"), _.get(rental, "oldVersion"))
  ) {
    yield put(redirect())
  }
}

export function* getRentalSubmission(action) {
  try {
    yield put(getRentalSubmissionRequest())
    const response = yield call(getRentalSubmissionApi, action.payload)
    if (
      !isEmpty(response?.proOption?.documents) &&
      response?.status !== RENTAL_STATUS.SUBMITTED
    ) {
      // TODO - Remove this logic once we confirm that document doesn't need to get on the guide page
      yield put(getDraftDocument(response?._id))
    }
    yield put(getRentalSubmissionSuccess(response))
    yield fork(checkStatusRental, null, response)
  } catch (err) {
    yield put(getRentalSubmissionFailed(err))
  }
}
