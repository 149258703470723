const LOG_LEVELS = { DEBUG: 0, INFO: 1, WARN: 2, ERROR: 3 }
const CURRENT_LEVEL =
  process.env.NODE_ENV !== "production" ? LOG_LEVELS.DEBUG : LOG_LEVELS.INFO

const logger = {
  debug: (...args) =>
    // eslint-disable-next-line no-console
    CURRENT_LEVEL <= LOG_LEVELS.DEBUG && console.debug("[DEBUG]", ...args),
  info: (...args) =>
    // eslint-disable-next-line no-console
    CURRENT_LEVEL <= LOG_LEVELS.INFO && console.info("[INFO]", ...args),
  warn: (...args) =>
    // eslint-disable-next-line no-console
    CURRENT_LEVEL <= LOG_LEVELS.WARN && console.warn("[WARN]", ...args),
  error: (...args) =>
    CURRENT_LEVEL <= LOG_LEVELS.ERROR && console.error("[ERROR]", ...args),
}

export default logger
