import React, { useEffect } from "react"
import { compose } from "redux"
import get from "lodash/get"
import { getLocalItem } from "@rentspree/cookie"
import { withRouter } from "react-router-dom"

import { STORAGE } from "constants/cookie"
import { FullScreenLoading } from "containers/loading"
import { StyledWrapperDarkGradient } from "components/layout"
import useAuth from "hooks/use-auth"
import { withReducer, withConnect, withSaga } from "./connect"
import { LogoHeaderContainer } from "./logo-header"

export const WrapperWithoutNavbar = ({ actions, match, children, loading }) => {
  const screeningRequestId = get(match, "params.screeningRequestId", "")

  const { isAuthenticated } = useAuth()

  useEffect(
    () => {
      actions.saveAllField({ screeningRequestId })
      // Auth0 Migration
      if (isAuthenticated || getLocalItem(STORAGE.USER_TOKEN)) {
        actions.redirect()
      } else {
        actions.getScreeningRequest(screeningRequestId)
      }
    },
    [actions, isAuthenticated, screeningRequestId],
  )

  return (
    <>
      {get(loading, "full", false) && <FullScreenLoading noneNavbar />}
      <StyledWrapperDarkGradient>
        <LogoHeaderContainer />
        {children}
      </StyledWrapperDarkGradient>
    </>
  )
}

export default compose(
  withReducer,
  withConnect,
  withSaga,
  withRouter,
)(WrapperWithoutNavbar)
