import split from "lodash/split"
import indexOf from "lodash/indexOf"
import { getFullUrl } from "./call-window"
import { LISTING_PARTNER } from "./partner-consts"

export const getSubdomain = () => {
  const full = getFullUrl()
  const parts = split(full, ".")

  if (
    indexOf(
      ["www", "rentspree", "localhost:3001", "localhost", ""],
      parts[0],
    ) === -1
  ) {
    return parts[0]
  }
  return "rentspree"
}

export const isListingPartner = subdomain =>
  Object.values(LISTING_PARTNER).includes(subdomain)
