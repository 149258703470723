import {
  put,
  call,
  spawn,
  select,
  takeEvery,
  all,
  fork,
  takeLatest,
  join,
  delay,
} from "redux-saga/effects"
import * as Sentry from "@sentry/browser"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import uniqueId from "lodash/uniqueId"
import takeRight from "lodash/takeRight"
import first from "lodash/first"
import { buildPath } from "@rentspree/path"
import { generateAddress } from "@rentspree/helper/build/generate-address"
import { store } from "store"
import { redirect } from "containers/wrapper/actions"
import { CancelToken } from "axios"
import SweetAlert from "components/sweetalert"
import { ALERT_PRESET, API_ERRORS } from "components/sweetalert/constants"
import { tracker } from "tracker"

import {
  TRACKING_EVENT_METHOD_NAME,
  DOCUMENT_UPLOAD_EVENT,
  DOCUMENT_TYPE_EVENT_MAPPER,
  PAYMENT_EVENT,
} from "tracker/tracker-const"
import {
  apiInstance,
  documentApiInstance,
  filesInstance,
  agentReviewApiInstance,
} from "utils/api-interceptor"
import message from "constants/error-messages"
import { SubmitRentalSubmissionError } from "utils/errors"
import { makeSelectScreeningOptionCreatedAt } from "containers/rental-submission/rental-instruction/selectors"
import { RENTAL_STATUS } from "containers/rental-submission/constants"
import {
  DELETE_DOCUMENT_FILE_API,
  DELETE_DOCUMENT_FILE_CALL,
  DOCUMENT_SETS_API,
  DRAFT_DOCUMENT_API,
  CANCEL_UNLISTED_DOCUMENTS_API,
  REPLACE_DOCUMENT_CALL,
  GET_DOCUMENT_SET_CALL,
  SAVE_UPLOADED_DOCUMENT_FILE_API,
  UPLOAD_AND_SAVE_DOCUMENT_FILE_CALL,
  UPLOAD_DOCUMENT_FILE_API,
  SUBMIT_RENTAL_SUBMISSION_API_URL,
  SUBMIT_RENTAL_SUBMISSION_CALL,
  CREATE_AGENT_REVIEW_PLACEHOLDER_TIMEOUT,
  DEFAULT_DOCUMENT_OPTION,
  LANDING_DOCUMENT_UPLOAD_CALL,
  INCOME_VERIFICATION_DOCUMENT_OPTION,
  DOCUMENT_CANCEL_SOURCE,
  SUBMIT_DOCUMENTS_API,
} from "./constants"

import {
  getDraftDocumentApiState,
  saveUploadedDocumentFileApiState,
  uploadDocumentFileApiState,
  setUploadingFileProgress,
  deleteDocumentFileApiState,
  getDocumentSetApiState,
  submitRentalSubmissionApiState,
  cancelUnlistedDocumentsApiState,
  landingDocumentUploadState,
} from "./actions"
import {
  selectRentalId,
  getRental,
  makeSelectCreditReport,
} from "../rental-submission/selectors"

import { showErrorAlertCall, clearErrorAlertCall } from "../error/constants"
import { selectDocument } from "./selectors"
import { makeSelectMultiShare } from "../wrapper/selectors"
import { SKIP_PAYMENT_PROCESS_API_URL } from "../tu-screening/constants"
import { ROUTE } from "../router/constants"
import {
  generateIncomeVerificationReportApi,
  getIncomeVerificationApi,
  getIncomeVerificationSaga,
} from "../rental-submission/income-verification/saga"
import { INCOME_VERIFICATION_STATUS } from "../rental-submission/income-verification/constants"
import {
  selectIncomeVerification,
  selectIsSubmittedIncomeVerification,
} from "../rental-submission/income-verification/selector"

export const getDraftDocumentApi = data =>
  documentApiInstance.get(buildPath(DRAFT_DOCUMENT_API, {}, data))

export const draftDocumentApi = data =>
  documentApiInstance.post(buildPath(DRAFT_DOCUMENT_API), { ...data })

export const cancelUnlistedDocumentsApi = data =>
  documentApiInstance.post(buildPath(CANCEL_UNLISTED_DOCUMENTS_API), {
    ...data,
  })

export const uploadDocumentFileApi = data => {
  const { fileTempId, files, rentalSubmissionId, documentId } = data
  const formData = new FormData()
  formData.append("files", files)
  formData.append("rentalSubmissionId", rentalSubmissionId)
  formData.append("documentId", documentId)
  return filesInstance.post(buildPath(UPLOAD_DOCUMENT_FILE_API), formData, {
    onUploadProgress: progressEvent => {
      const uploadPercent = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      )
      store.dispatch(
        setUploadingFileProgress({
          documentId,
          fileTempId,
          uploadPercent,
        }),
      )
    },
  })
}

export const submitDocumentsApi = async (documentSetId, types) =>
  documentApiInstance.put(buildPath(SUBMIT_DOCUMENTS_API, { documentSetId }), {
    types,
  })

export const saveUploadedDocumentFileApi = (params, body) =>
  documentApiInstance.post(
    buildPath(SAVE_UPLOADED_DOCUMENT_FILE_API, params),
    body,
  )

export const deleteDocumentFileApi = params =>
  documentApiInstance.delete(buildPath(DELETE_DOCUMENT_FILE_API, params))

export const getDocumentSetApi = data =>
  documentApiInstance.get(buildPath(DOCUMENT_SETS_API, {}, data))

export const submitRentalSubmissionApi = rentalId =>
  apiInstance.post(buildPath(SUBMIT_RENTAL_SUBMISSION_API_URL, { rentalId }))

export const createAgentReviewPlaceholderApi = async (
  data,
  cancelTokenSource = CancelToken.source(),
) => {
  // Set a timeout to make sure that creating an agent review after the renter submits the report is non-blocking.
  const timeout = setTimeout(() => {
    cancelTokenSource.cancel()
    console.error(
      `Create agent review placeholder API canceled due to exceeding the timeout of ${CREATE_AGENT_REVIEW_PLACEHOLDER_TIMEOUT}ms`,
    )
  }, CREATE_AGENT_REVIEW_PLACEHOLDER_TIMEOUT)

  try {
    await agentReviewApiInstance.post(buildPath(`/placeholder`), data, {
      cancelToken: cancelTokenSource.token,
      timeout: CREATE_AGENT_REVIEW_PLACEHOLDER_TIMEOUT,
    })
  } catch (error) {
    console.error("[Error] Cannot create agent review placeholder", error)
  } finally {
    clearTimeout(timeout)
  }
}

export const skipPaymentProcess = rentalId =>
  apiInstance.post(buildPath(SKIP_PAYMENT_PROCESS_API_URL, { rentalId }), {})

export function* getDocumentSet(action) {
  try {
    const response = yield call(getDocumentSetApi, {
      rentalSubmissionIds: action?.payload,
    })
    yield put(getDocumentSetApiState.success(first(response?.result)))
  } catch (error) {
    // Ignore this error since we don't wanna show it
  }
}

export function* draftDocument(rentalData) {
  try {
    const requestedAt = yield select(makeSelectScreeningOptionCreatedAt)
    const {
      id: rentalSubmissionId,
      landlord_id: userId,
      renter_id: renterId,
    } = rentalData
    const documents = yield call(draftDocumentApi, {
      rentalSubmissionId,
      userId,
      renterId,
      requestedAt,
      documents: DEFAULT_DOCUMENT_OPTION,
    })
    yield put(getDraftDocumentApiState.success(documents.result))
  } catch (error) {
    yield put(getDraftDocumentApiState.failure(error))
  }
}

export function* cancelUnlistedDocumentsSaga({ payload }) {
  yield put(cancelUnlistedDocumentsApiState.request())
  try {
    const rentalData = yield select(getRental)
    const requestedAt = yield select(makeSelectScreeningOptionCreatedAt)
    const { id: rentalSubmissionId, landlord_id: userId } = rentalData

    if (requestedAt) {
      const documents = yield call(cancelUnlistedDocumentsApi, {
        rentalSubmissionId,
        userId,
        requestedAt,
        documents: payload,
        cancelSource: DOCUMENT_CANCEL_SOURCE.INCOME_VERIFICATION,
      })
      yield put(cancelUnlistedDocumentsApiState.success(documents.result))
    } else {
      yield put(
        cancelUnlistedDocumentsApiState.failure(
          new Error("Parameters not complete"),
        ),
      )
    }
  } catch (error) {
    yield put(cancelUnlistedDocumentsApiState.failure(error))
  }
}

export function* getDraftDocument() {
  yield put(getDraftDocumentApiState.request())
  try {
    const rentalData = yield select(getRental)
    const response = yield call(getDraftDocumentApi, {
      rentalSubmissionId: rentalData.id,
    })
    let documents = response?.result
    const hasIncome = rentalData.screeningOption?.income
    if (hasIncome && documents.length === 0) {
      documents = yield call(draftDocument, rentalData)
    } else {
      yield put(getDraftDocumentApiState.success(first(documents)))
    }
  } catch (error) {
    yield put(getDraftDocumentApiState.failure(error))
  }
}

export function* uploadAndSaveDocumentFile(action) {
  const rentalSubmissionId = yield select(selectRentalId)
  const documentSet = yield select(selectDocument)
  const documentSetId = get(documentSet, "id")
  const documentId = get(action, "payload.documentId")
  const documentType = documentSet.documents.find(doc => doc.id === documentId)
    ?.type
  const files = get(action, "payload.files")
  const filesWithTempId = files.map(file => ({
    file,
    fileTempId: uniqueId(documentId),
  }))
  const tasks = yield all(
    filesWithTempId.map(fileWithTempId =>
      fork(uploadSingleFile, {
        rentalSubmissionId,
        documentSetId,
        documentId,
        ...fileWithTempId,
      }),
    ),
  )
  const uploadFileResponse = yield all(tasks.map(task => join(task)))
  const successResponse = uploadFileResponse.filter(result => result?.success)
  const clickFrom = get(action, "payload.clickFrom")
  yield call(saveUploadedDocumentFile, {
    documentSetId,
    documentId,
    documentType,
    uploadedFiles: successResponse.map(result => ({
      serverName: get(first(result.result), "name"),
      originalName: filesWithTempId.find(
        ({ fileTempId }) => fileTempId === result.fileTempId,
      )?.file?.name,
      tempId: filesWithTempId.find(
        ({ fileTempId }) => fileTempId === result.fileTempId,
      )?.fileTempId,
    })),
    clickFrom,
  })
}

export function* saveUploadedDocumentFile({
  documentSetId,
  documentId,
  documentType,
  uploadedFiles,
  clickFrom,
}) {
  const rentalSubmission = yield select(getRental)
  const { id: rentalSubmissionId, property } = rentalSubmission
  try {
    yield put(
      saveUploadedDocumentFileApiState.request({
        documentId,
        files: uploadedFiles,
      }),
    )
    const savedFileResponse = yield call(
      saveUploadedDocumentFileApi,
      {
        documentSetId,
        documentId,
      },
      {
        rentalSubmissionId,
        files: uploadedFiles.map(file => ({
          originalName: file?.originalName,
          serverName: file?.serverName,
        })),
      },
    )
    const savedFiles = takeRight(
      savedFileResponse?.result?.files,
      uploadedFiles.length,
    )
    yield put(
      saveUploadedDocumentFileApiState.success({
        documentId,
        files: savedFiles.map((file, idx) => ({
          ...file,
          tempId: uploadedFiles[idx]?.tempId,
        })),
      }),
    )
    yield all(
      savedFiles.map(file =>
        call([tracker, "trackEvent"], DOCUMENT_UPLOAD_EVENT.UPLOAD_DOCUMENT, {
          doc: DOCUMENT_TYPE_EVENT_MAPPER[documentType],
          rental_id: rentalSubmissionId,
          address: generateAddress(property),
          file_name: file.originalName,
          click_from: clickFrom,
        }),
      ),
    )
    yield put(clearErrorAlertCall())
  } catch (error) {
    yield put(
      showErrorAlertCall({
        message: message.documentUploadFailed,
        isScrollTop: true,
      }),
    )
    yield put(saveUploadedDocumentFileApiState.failure({ documentId }))
  }
}

export function* uploadSingleFile({
  rentalSubmissionId,
  documentId,
  file,
  fileTempId,
}) {
  yield put(
    uploadDocumentFileApiState.request({ documentId, fileTempId, file }),
  )
  try {
    const uploadedFile = yield call(uploadDocumentFileApi, {
      rentalSubmissionId,
      documentId,
      files: file,
      fileTempId,
    })
    yield put(uploadDocumentFileApiState.success({ documentId, fileTempId }))
    return { ...uploadedFile, fileTempId }
  } catch (error) {
    yield put(
      showErrorAlertCall({
        message: message.documentUploadFailed,
        isScrollTop: true,
      }),
    )
    yield put(uploadDocumentFileApiState.failure({ documentId, fileTempId }))
    return null
  }
}

export function* deleteDocumentFile(action) {
  const { documentId, fileId } = action?.payload || {}
  yield put(deleteDocumentFileApiState.request({ documentId, fileId }))
  const documentSet = yield select(selectDocument)
  try {
    yield call(deleteDocumentFileApi, {
      documentSetId: documentSet?.id,
      documentId,
      fileId,
    })
    yield put(deleteDocumentFileApiState.success({ documentId, fileId }))
    yield put(clearErrorAlertCall())
  } catch (error) {
    yield put(deleteDocumentFileApiState.failure({ documentId, fileId }))
    yield put(
      showErrorAlertCall({
        message: message.documentDeleteFailed,
        isScrollTop: true,
      }),
    )
  }
}

export function* generateIncomeVerificationReportSaga(rentalSubmissionId) {
  const payload = {
    rentalSubmissionId,
  }
  const incomeVerification = yield call(getIncomeVerificationApi, {
    query: payload,
  })

  if (
    [
      INCOME_VERIFICATION_STATUS.SUBMITTED,
      INCOME_VERIFICATION_STATUS.READY,
      INCOME_VERIFICATION_STATUS.OPENED,
    ].includes(incomeVerification?.status)
  ) {
    yield call(generateIncomeVerificationReportApi, { query: payload })
  }
}

export function* submitRentalSubmission(action) {
  const { documentSetId, types } = action?.payload
  const rentalSubmission = yield select(getRental)
  const incomeVerification = yield select(selectIncomeVerification())
  const creditReport = yield select(makeSelectCreditReport())
  const multiShare = yield select(makeSelectMultiShare())
  const { isMultiShareActive: isEnableMultiShare } = multiShare
  const shouldSkipPayment = creditReport && isEnableMultiShare
  const isSubmitted = [
    RENTAL_STATUS.SUBMITTED,
    RENTAL_STATUS.PROPERTY_REQUIRED,
  ].includes(get(rentalSubmission, "status"))
  let response = null

  yield put(submitRentalSubmissionApiState.request())
  try {
    if (shouldSkipPayment) {
      yield call(skipPaymentProcess, rentalSubmission?._id)
    }
    if (!isSubmitted) {
      response = yield call(submitRentalSubmissionApi, rentalSubmission?._id)
      yield call(
        [tracker, TRACKING_EVENT_METHOD_NAME],
        PAYMENT_EVENT.submitSuccess,
      )
      yield delay(200)

      const createAgentReviewPlaceholderPayload = {
        // eslint-disable-next-line camelcase
        reviewee: { userId: response?.landlord_id },
        rentalSubmissionId: response?._id,
        createdFrom: "tenant_screening",
      }
      yield call(
        createAgentReviewPlaceholderApi,
        createAgentReviewPlaceholderPayload,
      )
    } else if (!isEmpty(types)) {
      response = yield call(submitDocumentsApi, documentSetId, types)
    }
    if (incomeVerification?.id) {
      yield spawn(generateIncomeVerificationReportSaga, rentalSubmission?._id)
    }
    yield put(submitRentalSubmissionApiState.success(response))

    yield put(redirect(ROUTE.GUIDE.FINISH, true))
  } catch (err) {
    yield call(
      Sentry.captureException,
      new SubmitRentalSubmissionError(JSON.stringify(err)),
    )
    yield put(submitRentalSubmissionApiState.failure(err))
    yield call([tracker, TRACKING_EVENT_METHOD_NAME], PAYMENT_EVENT.submitFail)
    yield call(
      SweetAlert,
      ALERT_PRESET.TRY_AGAIN,
      API_ERRORS[500].option,
      API_ERRORS[500].callback,
    )
  }
}

export function* landingDocumentUploadPageSaga() {
  yield put(landingDocumentUploadState.request())
  try {
    const rentalData = yield select(getRental)
    if (rentalData.screeningOption.income) {
      const isSubmitIncomeVerification = yield select(
        selectIsSubmittedIncomeVerification(),
      )
      const incomeVerification = yield call(getIncomeVerificationSaga, {
        payload: { rentalSubmissionId: rentalData.id },
      })
      if (
        [
          INCOME_VERIFICATION_STATUS.SUBMITTED,
          INCOME_VERIFICATION_STATUS.READY,
        ].includes(get(incomeVerification, "status")) ||
        isSubmitIncomeVerification
      ) {
        yield call(cancelUnlistedDocumentsSaga, {
          payload: INCOME_VERIFICATION_DOCUMENT_OPTION,
        })
        yield put(
          landingDocumentUploadState.success({ isRenderIdUpload: true }),
        )
      } else {
        yield put(
          landingDocumentUploadState.success({ isRenderIdUpload: false }),
        )
      }
    } else {
      yield put(landingDocumentUploadState.success({ isRenderIdUpload: false }))
    }
  } catch (err) {
    yield put(landingDocumentUploadState.failure(err))
  }
}

export function* watchSubmitRentalSubmission() {
  yield takeLatest(SUBMIT_RENTAL_SUBMISSION_CALL, submitRentalSubmission)
}

export function* watchDeleteDocumentFile() {
  yield takeEvery(DELETE_DOCUMENT_FILE_CALL, deleteDocumentFile)
}

export function* watchUploadAndSaveDocument() {
  yield takeEvery(UPLOAD_AND_SAVE_DOCUMENT_FILE_CALL, uploadAndSaveDocumentFile)
}

export function* watchGetDocumentSet() {
  yield takeLatest(GET_DOCUMENT_SET_CALL, getDocumentSet)
}

export function* watchCancelUnlistedDocuments() {
  yield takeLatest(REPLACE_DOCUMENT_CALL, cancelUnlistedDocumentsSaga)
}

export function* watchLandingDocumentUploadPage() {
  yield takeLatest(LANDING_DOCUMENT_UPLOAD_CALL, landingDocumentUploadPageSaga)
}

export function* rootSaga() {
  yield all([
    watchUploadAndSaveDocument(),
    watchDeleteDocumentFile(),
    watchSubmitRentalSubmission(),
    watchCancelUnlistedDocuments(),
    watchLandingDocumentUploadPage(),
  ])
}

export default rootSaga
