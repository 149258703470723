import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { createStructuredSelector } from "reselect"
import { redirect } from "containers/wrapper/actions"
import selectLoading from "containers/loading/selectors"

import { getEnvelopeByIdCall, handleAfterEnvelopeSign } from "../actions"
import { makeSelectRental } from "../../../containers/rental-submission/selectors"
import {
  selectAfterSignStatus,
  selectEnvelopeData,
  selectIsSigned,
  selectLoadingPrepareEnvelope,
} from "../selectors"

export const makeMapStateToProps = () =>
  createStructuredSelector({
    rentalSubmission: makeSelectRental(),
    afterSignStatus: selectAfterSignStatus,
    isSigned: selectIsSigned(),
    isLoadingPrepareEnvelope: selectLoadingPrepareEnvelope,
    envelope: selectEnvelopeData(),
    loading: selectLoading(),
  })

export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        handleAfterEnvelopeSign,
        getEnvelopeByIdCall,
        redirect,
      },
      dispatch,
    ),
  }
}

export const withConnect = connect(
  makeMapStateToProps,
  mapDispatchToProps,
)
