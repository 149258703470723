/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import "@babel/polyfill"

// Sentry package
import * as Sentry from "@sentry/browser"

// Import all the third party stuff
import { datadogRum } from "@datadog/browser-rum"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { ConnectedRouter } from "connected-react-router/immutable"

import "sanitize.css/sanitize.css"

// Import root app
import Router from "containers/router"

// Import Language Provider
import LanguageProvider from "containers/language-provider"

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
// import "!file-loader?name=[name].[ext]!./images/favicon.ico"
import "file-loader?name=[name].[ext]!./.htaccess"
/* eslint-enable import/no-unresolved, import/extensions */

// import configureStore from "./configure-store"
import { setOption } from "@rentspree/cookie"

import { ThemeProvider, createTheme } from "@mui/material/styles"
import { muiTheme } from "@rentspree/component-2023.theme.mui"
import "@fortawesome/fontawesome-free/css/all.min.css"

// Import i18n messages
import { translationMessages } from "./i18n"

// Import CSS reset and Global Styles
import "./styles/index"

import { store } from "./store"

import { COOKIE_OPTION } from "./constants/cookie"

// Sentry env
import {
  ENVIRONMENT,
  SENTRY_DSN,
  CI_COMMIT_SHA,
  API_URL,
  USER_API_URL,
  FILES_URL,
  REX_API_URL,
  IMAGE_PROXY_HOST,
  DATADOG_SITE,
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  DATADOG_TRACE_SAMPLE_RATE,
  DATADOG_SAMPLE_RATE,
  DATADOG_REPLAY_SAMPLE_RATE,
} from "./env"
import packageJson from "../package.json"
import history from "./utils/history"
import { AuthProvider } from "./providers/auth-provider"

// Create redux store with history
// const initialState = {}
setOption(COOKIE_OPTION)
// const store = configureStore(initialState, history)
const MOUNT_NODE = document.getElementById("app")

// Initiate Sentry
if (ENVIRONMENT) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: ENVIRONMENT === "Dev" ? CI_COMMIT_SHA : packageJson.version,
    environment: ENVIRONMENT,
  })
}

// Initiate DataDog RUM
if (DATADOG_APPLICATION_ID && DATADOG_CLIENT_TOKEN && DATADOG_SITE) {
  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: packageJson.name,
    env: ENVIRONMENT,
    version: packageJson.version,
    sessionSampleRate: DATADOG_SAMPLE_RATE,
    sessionReplaySampleRate: DATADOG_REPLAY_SAMPLE_RATE,
    traceSampleRate: DATADOG_TRACE_SAMPLE_RATE,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [
      { match: API_URL, propagatorTypes: ["b3", "b3multi"] },
      { match: USER_API_URL, propagatorTypes: ["b3", "b3multi"] },
      { match: FILES_URL, propagatorTypes: ["b3", "b3multi"] },
      { match: REX_API_URL, propagatorTypes: ["b3", "b3multi"] },
      { match: IMAGE_PROXY_HOST, propagatorTypes: ["b3", "b3multi"] },
    ],
    excludedActivityUrls: [
      /^https:\/\/api\.amplitude\.com/,
      /^https:\/\/analytics\.google\.com/,
      /^https:\/\/.*\.clarity\.ms\/collect/,
      /^https:\/\/settings\.luckyorange\.net/,
      /^https:\/\/.*\.talkjs\.com/,
      /^https:\/\/status\.rentspree\.com\/embed\/script\.js/,
      /^https:\/\/.*\.statuspage\.io/,
      /^https:\/\/.*\.hs-scripts\.com/,
      /^https:\/\/.*\.hs-banner\.com/,
      /^https:\/\/.*\.hs-analytics\.net/,
      /^https:\/\/.*\.hubspotfeedback\.com/,
      /^https:\/\/.*\.hubspot\.com/,
      /^https:\/\/.*\.hsappstatic\.net/,
      /^https:\/\/.*\.hsforms\.com/,
      /^https:\/\/.*\.hsforms\.net/,
      /^https:\/\/.*\.hsleadflows\.net/,
    ],
  })
}

const theme = createTheme(muiTheme)

const render = messages => {
  ReactDOM.render(
    <AuthProvider>
      <Provider store={store}>
        <LanguageProvider messages={messages}>
          <ThemeProvider theme={theme}>
            <ConnectedRouter history={history}>
              <Router history={history} />
            </ConnectedRouter>
          </ThemeProvider>
        </LanguageProvider>
      </Provider>
    </AuthProvider>,
    MOUNT_NODE,
  )
}

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(["./i18n", "containers/router"], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE)
    render(translationMessages)
  })
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import("intl"))
  })
    .then(() => Promise.all([import("intl/locale-data/jsonp/en.js")]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err
    })
} else {
  render(translationMessages)
}
