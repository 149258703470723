import React, { useRef } from "react"
import Button from "@rentspree/component-2023.components.atoms.button"

import { COLOR } from "styles/settings"
import { SignFlagWrapper, StyledArrowButton } from "./styled-index"

const ArrowButton = ({
  bgColor = COLOR.GOLDEN_HOUR_80,
  bodyLength,
  width,
  height,
  text,
}) => (
  <StyledArrowButton
    size="small"
    variant="contained"
    data-testid="sign-flag-arrow-button"
    bodyLength={bodyLength}
    bgColor={bgColor}
    width={width}
    height={height}>
    {text}
  </StyledArrowButton>
)

export const SignFlag = ({ buttonType, large, text, ...rest }) => {
  const divRef = useRef(null)
  let button
  switch (buttonType) {
    case "arrow":
      button = (
        <ArrowButton
          /*
           * bodyLength fixed to 100px to prevent weird shape shifting
           * when it changing from 'start' to 'sign' flag
           */
          bodyLength="100px"
          width="120px"
          height="38px"
          text={text}
          {...rest}
        />
      )
      break
    default:
      button = (
        <Button
          data-testid="sign-flag-primary-button"
          variant="contained"
          color="primary"
          sx={{ width: "100px" }}
          size="small">
          {text}
        </Button>
      )
      break
  }
  return (
    <SignFlagWrapper ref={divRef} large={large}>
      {button}
    </SignFlagWrapper>
  )
}
