import React, { useEffect, useState } from "react"

import { injectRequestInterceptor } from "utils/api-interceptor"
import { feedbackCollectionInstance } from "utils/feedback-collection/instance"
import AuthContext from "./auth.context"
import useAuth from "../hooks/use-auth"
import { FullScreenLoading } from "../containers/loading"

export const AuthWrapper = ({ children }) => {
  const { isAuthenticated, isLoading, getAccessToken } = useAuth()
  const [isInitializedAuth, setIsInitializedAuth] = useState(false)

  useEffect(
    () => {
      if (isLoading) return
      injectRequestInterceptor({
        getAccessToken: isAuthenticated ? getAccessToken : undefined,
      })
      if (isAuthenticated) {
        feedbackCollectionInstance.setAuthConfiguration({
          getAccessToken,
        })
      }
      setIsInitializedAuth(true)
    },
    [isAuthenticated, isLoading, getAccessToken],
  )
  if (!isInitializedAuth) return <FullScreenLoading />

  return (
    <AuthContext.Provider value={{ authContext: true }}>
      {children}
    </AuthContext.Provider>
  )
}
