import { SignBox } from "components/drag-and-drop/sign-box"
import { InitialBox } from "components/drag-and-drop/initial-box"
import { TextBox } from "components/drag-and-drop/text-box"
import { Checkbox } from "components/drag-and-drop/check-box"
import { DateSignBox } from "components/drag-and-drop/date-sign-box"

export const ENVELOPE_CALL = "ENVELOPE_CALL"
export const ENVELOPE_REQUEST = "ENVELOPE_REQUEST"
export const ENVELOPE_SUCCESS = "ENVELOPE_SUCCESS"
export const ENVELOPE_FAILURE = "ENVELOPE_FAILURE"
export const GET_ENVELOPE_CALL = "GET_ENVELOPE_CALL"
export const GET_ENVELOPE_CALL_REQUEST = "GET_ENVELOPE_CALL_REQUEST"
export const GET_ENVELOPE_CALL_SUCCESS = "GET_ENVELOPE_CALL_SUCCESS"
export const GET_ENVELOPE_CALL_FAILURE = "GET_ENVELOPE_CALL_FAILURE"

export const UPDATE_ENVELOPE_SIGN_CALL = "UPDATE_ENVELOPE_SIGN_CALL"
export const UPDATE_ENVELOPE_SIGN_REQUEST = "UPDATE_ENVELOPE_SIGN_REQUEST"
export const UPDATE_ENVELOPE_SIGN_SUCCESS = "UPDATE_ENVELOPE_SIGN_SUCCESS"
export const UPDATE_ENVELOPE_SIGN_FAILED = "UPDATE_ENVELOPE_SIGN_FAILED"

export const HANDLE_AFTER_ENVELOPE_SIGN_CALL = "HANDLE_AFTER_ENVELOPE_SIGN_CALL"
export const HANDLE_AFTER_ENVELOPE_SIGN_REQUEST =
  "HANDLE_AFTER_ENVELOPE_SIGN_REQUEST"
export const HANDLE_AFTER_ENVELOPE_SIGN_SUCCESS =
  "HANDLE_AFTER_ENVELOPE_SIGN_SUCCESS"
export const HANDLE_AFTER_ENVELOPE_SIGN_FAILED =
  "HANDLE_AFTER_ENVELOPE_SIGN_FAILED"

export const SHARE_ENVELOPE_CALL = "SHARE_ENVELOPE_CALL"
export const SHARE_ENVELOPE_REQUEST = "SHARE_ENVELOPE_REQUEST"
export const SHARE_ENVELOPE_SUCCESS = "SHARE_ENVELOPE_SUCCESS"
export const SHARE_ENVELOPE_FAILED = "SHARE_ENVELOPE_FAILED"
export const BACK_TO_LEASE_TEXT = "Back to transaction"

export const DATE_SIGNED_SAVE = "DATE_SIGNED_SAVE"
export const SIGN_SIGNATURE_SAVE = "SIGN_SIGNATURE_SAVE"
export const SIGN_SIGNATURE_EDIT = "SIGN_SIGNATURE_EDIT"
export const UPLOAD_SIGN_SIGNATURE = "UPLOAD_SIGN_SIGNATURE"

export const UPLOAD_SIGNATURE_REQUEST = "UPLOAD_SIGNATURE_REQUEST"
export const UPLOAD_SIGNATURE_SUCCESS = "UPLOAD_SIGNATURE_SUCCESS"
export const UPLOAD_SIGNATURE_FAILED = "UPLOAD_SIGNATURE_FAILED"

export const SAVE_FILES_ENVELOPE = "SAVE_FILES_ENVELOPE"
export const SAVE_FILES_ENVELOPE_REQUEST = "SAVE_FILES_ENVELOPE_REQUEST"
export const SAVE_FILES_ENVELOPE_SUCCESS = "SAVE_FILES_ENVELOPE_SUCCESS"
export const SAVE_FILES_ENVELOPE_FAILED = "SAVE_FILES_ENVELOPE_FAILED"
export const REMOVE_ACTIVE_BOX = "REMOVE_ACTIVE_BOX"

export const GENERATE_PDF_FILE_URL = "GENERATE_PDF_FILE_URL"
export const GENERATE_PDF_FILE_URL_REQUEST = "GENERATE_PDF_FILE_URL_REQUEST"
export const GENERATE_PDF_FILE_URL_SUCCESS = "GENERATE_PDF_FILE_URL_SUCCESS"
export const GENERATE_PDF_FILE_URL_FAILED = "GENERATE_PDF_FILE_URL_FAILED"

export const UPDATE_ENVELOPE_CONSENT_CALL = "UPDATE_ENVELOPE_CONSENT_CALL"
export const UPDATE_ENVELOPE_CONSENT_REQUEST = "UPDATE_ENVELOPE_CONSENT_REQUEST"
export const UPDATE_ENVELOPE_CONSENT_SUCCESS = "UPDATE_ENVELOPE_CONSENT_SUCCESS"
export const UPDATE_ENVELOPE_CONSENT_FAILED = "UPDATE_ENVELOPE_CONSENT_FAILED"

export const SET_RECIPIENT_ID_SELECTED = "SET_RECIPIENT_ID_SELECTED"

export const SHOW_CONSENT_MODAL = "SHOW_CONSENT_MODAL"

export const UPDATE_ENVELOPE_TEMPLATE_CALL = "UPDATE_ENVELOPE_TEMPLATE_CALL"
export const UPDATE_ENVELOPE_TEMPLATE_REQUEST =
  "UPDATE_ENVELOPE_TEMPLATE_REQUEST"
export const UPDATE_ENVELOPE_TEMPLATE_SUCCESS =
  "UPDATE_ENVELOPE_TEMPLATE_SUCCESS"
export const UPDATE_ENVELOPE_TEMPLATE_FAILED = "UPDATE_ENVELOPE_TEMPLATE_FAILED"

export const UPDATE_CONSENT_SELECT_OPTION_CALL =
  "UPDATE_CONSENT_SELECT_OPTION_CALL"
export const UPDATE_LEASE_AGREEMENT_CONSENT_CALL =
  "UPDATE_LEASE_AGREEMENT_CONSENT_CALL"
export const UPDATE_LEASE_AGREEMENT_CONSENT_REQUEST =
  "UPDATE_LEASE_AGREEMENT_CONSENT_REQUEST"
export const UPDATE_LEASE_AGREEMENT_CONSENT_SUCCESS =
  "UPDATE_LEASE_AGREEMENT_CONSENT_SUCCESS"
export const UPDATE_LEASE_AGREEMENT_CONSENT_FAILED =
  "UPDATE_LEASE_AGREEMENT_CONSENT_FAILED"

export const CLEAR_LEASE_ERROR = "CLEAR_LEASE_ERROR"

export const FIT_BUTTON = {
  FIT_TO_PAGE: {
    text: "Fit to page",
    icon: "full-page",
    track: "fit_to_page",
  },
  FIT_TO_WIDTH: {
    text: "Fit to width",
    icon: "full-width",
    track: "fit_to_width",
  },
}

export const ENVELOPE_STATUS = {
  CREATED: "created",
  INPROGRESS: "inprogress",
  PENDING: "pending",
  COMPLETED: "completed",
  VOID: "void",
}

export const RECIPIENT_STATUS = {
  PENDING: "pending",
  SIGNED: "signed",
  UNSIGNED: "unsigned",
}

export const DOCUMENT_LIST_ID = "documentList"

export const GET_USER_INTEGRATION_REQUEST = "GET_USER_INTEGRATION_REQUEST"
export const GET_USER_INTEGRATION_SUCCESS = "GET_USER_INTEGRATION_SUCCESS"
export const GET_USER_INTEGRATION_FAIL = "GET_USER_INTEGRATION_FAIL"

export const MAP_COMPONENT = {
  SIGNATURE_SIGNS: SignBox,
  INITIAL_SIGNS: InitialBox,
  TEXT_BOX: TextBox,
  CHECK_BOX: Checkbox,
  DATE_SIGNS: DateSignBox,
}

export const TYPES_COMPONENT = {
  TEXT_BOX: "TEXT_BOX",
  CHECK_BOX: "CHECK_BOX",
  INITIAL_SIGNS: "INITIAL_SIGNS",
  SIGNATURE_SIGNS: "SIGNATURE_SIGNS",
  DATE_SIGNS: "DATE_SIGNS",
  ADDRESS: "ADDRESS",
  DATE_BOX: "DATE_BOX",
  AMOUNT_BOX: "AMOUNT_BOX",
  TIME_BOX: "TIME_BOX",
}

export const SIGN_TYPE = [
  TYPES_COMPONENT.SIGNATURE_SIGNS,
  TYPES_COMPONENT.INITIAL_SIGNS,
]

export const ACCEPTED_TYPES = [
  TYPES_COMPONENT.TEXT_BOX,
  TYPES_COMPONENT.CHECK_BOX,
  TYPES_COMPONENT.INITIAL_SIGNS,
  TYPES_COMPONENT.SIGNATURE_SIGNS,
  TYPES_COMPONENT.DATE_SIGNS,
  TYPES_COMPONENT.DATE_BOX,
  TYPES_COMPONENT.AMOUNT_BOX,
  TYPES_COMPONENT.TIME_BOX,
]

export const MUST_ASSIGNED_COMPONENTS = [
  TYPES_COMPONENT.INITIAL_SIGNS,
  TYPES_COMPONENT.SIGNATURE_SIGNS,
  TYPES_COMPONENT.DATE_SIGNS,
]

export const PREPARE_ENVELOPE_CALL = "PREPARE_ENVELOPE_CALL"
export const PREPARE_ENVELOPE_REQUEST = "PREPARE_ENVELOPE_REQUEST"
export const PREPARE_ENVELOPE_SUCCESS = "PREPARE_ENVELOPE_SUCCESS"
export const PREPARE_ENVELOPE_FAILED = "PREPARE_ENVELOPE_FAILED"

export const GENERATE_ENVELOPE_PDF_CALL = "GENERATE_ENVELOPE_PDF_CALL"
export const GENERATE_ENVELOPE_PDF_REQUEST = "GENERATE_ENVELOPE_PDF_REQUEST"
export const GENERATE_ENVELOPE_PDF_SUCCESS = "GENERATE_ENVELOPE_PDF_SUCCESS"
export const GENERATE_ENVELOPE_PDF_FAILED = "GENERATE_ENVELOPE_PDF_FAILED"

export const DATE_FIELD_FORMAT = "{{DATE}}"
export const TIME_FIELD_FORMAT = "{{TIME}}"

export const RECIPIENT_ROLES = {
  APPLICANT: "applicant",
}
