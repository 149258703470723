import React from "react"
import { Popper } from "react-popper"
import styled from "styled-components"
import { S16 } from "@rentspree/component-v2"
import { COLOR } from "styles/settings"

export const Wrapper = styled.div`
  font-family: Source Sans Pro, sans-serif;
  z-index: 2;
  @media (max-width: 991px) {
    display: none;
  }
`

export const Container = styled.div`
  pointer-events: none;
  display: flex;
  top: -29px !important;
  flex-direction: column;
  padding: 5px;
  z-index: 9999;
  border-radius: 5px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  background-color: ${COLOR.GOLDEN_HOUR_80};
`
export const Item = styled(S16)`
  display: flex;
  padding: 0px 5px;
  font-size: 12px;
  font-weight: normal;
  color: white;
`
const TooltipPopper = ({ refNode, message }) => {
  if (refNode !== "") {
    try {
      return (
        <Wrapper>
          <Popper referenceElement={refNode} placement="top">
            {({ ref, placement, style }) => (
              <Container
                ref={ref}
                style={{ ...style, whiteSpace: "nowrap" }}
                data-placement={placement}>
                <Item margin="0">{message || "required"}</Item>
              </Container>
            )}
          </Popper>
        </Wrapper>
      )
    } catch (e) {
      return null
    }
  }
  return null
}

export default TooltipPopper
