/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prefer-stateless-function */
import React from "react"

import get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import { compose } from "redux"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import { buildPath, query } from "@rentspree/path"
import dateFns from "date-fns"
import { Statsig, useGate } from "statsig-react"
import { CENTRALIZED_SIGN_PAGE } from "utils/feature-flag/constants"

import track from "react-tracking"
import { tracker, withTracker } from "tracker"
import {
  RENTAL_PAGE_NAME,
  RENTAL_GUIDE_EVENT,
  PAYMENT_EVENT,
  needPayment,
  DOCUMENT_UPLOAD_EVENT,
  INSTRUCTION_EVENT,
} from "tracker/tracker-const"
import {
  T36,
  S24,
  B14,
  BlueGradientButton,
  Hr,
  S18,
  Span,
} from "@rentspree/component-v2"
import { generateAddress } from "@rentspree/helper"
import { RentalStep, FlexContainer } from "components/guide/rental-step"
import { RentalDetail, AppFeeWithTooltip } from "components/guide/rental-detail"
import { STATUS, RENTAL_STEP, SubmitTUWording } from "components/guide/constant"
import {
  MobileHr,
  SubmitButtonWrapper,
} from "components/guide/rental-guide-style"
import { DocumentStep } from "components/guide/document-step"
import { APPLICANT_PATH_FLOW } from "containers/applicant-info/constants"
import { SpecialOfferStep } from "components/guide/special-offer-step"
import { ROUTE } from "containers/router/constants"
import { TU_BUNDLE_ID } from "containers/tu-screening/constants"
import { ErrorAlert } from "components/alerts/error"
import message from "constants/error-messages"
import { USER_TYPE } from "constants/user"
import Scroll from "react-scroll"

import { COLOR } from "styles/settings"
import { locationAssign } from "utils/call-window"

import { InfoMessagePanel } from "components/guide/info-message-panel"
import { withConnect, withSaga, withReducer } from "./connect"
import {
  getDocumentUploadPurpose,
  makeSubmitButtonProps,
  getApplicationFeeContent,
  getMultiShareSelectedOptionKey,
  getRedirectConditionParams,
} from "./helper"
import {
  RENTAL_STATUS,
  CREDIT_FOR_RENTER_VIEW_REPORT_STATUS,
  NUMBER_OF_DAYS_TO_DISPLAY_MULTI_SHARE_EXPIRED,
  PAYER_TYPE,
  CREDIT_REPORT_STATUS,
} from "../constants"
import { generateMultiShareMessage } from "../helper"
import { MULTI_SHARE_COPIES } from "./constants"
import {
  withSaga as withIncomeVerificationSaga,
  withReducer as withIncomeVerificationReducer,
} from "../income-verification/connect"
import { INCOME_VERIFICATION_STATUS } from "../income-verification/constants"
import {
  withReducer as withTuScreeningReducer,
  withSaga as withTuScreeningSaga,
} from "../../../containers/tu-screening/connect"

const scroll = Scroll.scroller

const WrapperWording = styled.div`
  p {
    line-height: 16px;
    margin-bottom: 12px;
  }
`

const RentalGuideTitle = styled(T36)`
  margin-top: "50px";
  margin-bottom: "5px";
`

const MultiShareOfferTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 767px) {
    gap: 10px;
    flex-direction: column-reverse;
  }
`
const MultiShareOfferSubTitleContainer = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${COLOR.textBlack};
`

export class GuidePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showError: false,
      isStepCompleted: false,
      shouldCheckRedirect: true,
    }
  }

  componentDidMount() {
    const { rentalSubmission } = this.props

    this.props.actions.getCreditReport()

    if (rentalSubmission.screeningOption.income)
      this.props.actions.getIncomeVerification({
        rentalSubmissionId: this.props.rentalSubmission.id,
      })

    const isStepCompleted = this.isStepComplete()
    if (isStepCompleted) {
      scroll.scrollTo("submit-button", {
        duration: 500,
        smooth: true,
        offset: -40,
      })
    }

    // LNR-3649: temporary remove statsig event tracking due to quota limit
    // this.logStatSigEvent("apply_now::enter rental guide")

    const { reminder } = query.parse(this.props.location?.search)
    if (reminder) {
      tracker.trackEvent(
        INSTRUCTION_EVENT.RESUME_APPLICATION_FROM_REMINDER_EMAIL,
        {
          reminder,
          // eslint-disable-next-line no-underscore-dangle
          rental_submission_id: rentalSubmission._id,
        },
      )
    }
  }

  componentDidUpdate() {
    const { rentalSubmission } = this.props
    const { shouldCheckRedirect, showError } = this.state

    if (!rentalSubmission || !shouldCheckRedirect || showError) return

    this.handleResumeApplicationFlow()

    if (
      CREDIT_FOR_RENTER_VIEW_REPORT_STATUS.includes(
        rentalSubmission.credit_report?.status,
      )
    ) {
      this.onShowError()
    }
  }

  handleResumeApplicationFlow() {
    const { actions, rentalSubmission, incomeVerification } = this.props
    const isRenterPay =
      rentalSubmission.screeningOption.payerType === PAYER_TYPE.RENTER
    const hasIncome = rentalSubmission.screeningOption.income
    const hasCreditReport = rentalSubmission.screeningOption.credit_report
    const isOpenedCreditReport =
      rentalSubmission.credit_report?.status === CREDIT_REPORT_STATUS.OPENED

    if (hasIncome && !incomeVerification?.status) {
      return
    }

    if (!isRenterPay || !hasCreditReport || isOpenedCreditReport) {
      if (this.shouldRedirectToSubmittedPage(rentalSubmission)) {
        this.markShouldCheckRedirectToFalse()
        actions.redirect(ROUTE.GUIDE.SUBMITTED)
        return
      }

      if (hasIncome) {
        if (
          this.shouldRedirectToIncomeVerification(incomeVerification?.status)
        ) {
          actions.redirect(ROUTE.INCOME_VERIFICATION, true)
          this.markShouldCheckRedirectToFalse()
          return
        }

        if (this.shouldRedirectToDocumentUpload(incomeVerification?.status)) {
          this.markShouldCheckRedirectToFalse()
          actions.redirect(ROUTE.DOCUMENT_UPLOAD, true)
          return
        }
      }
    }

    if (this.shouldRedirectToReviewReportPage(rentalSubmission)) {
      const destinationPath = buildPath(ROUTE.DASHBOARD_AGENT_REPORT, {
        rentalId: rentalSubmission._id,
      })
      this.markShouldCheckRedirectToFalse()
      locationAssign(destinationPath)
      return
    }

    this.markShouldCheckRedirectToFalse()
  }

  shouldRedirectToIncomeVerification(status) {
    const incomeVerificationStatuses = [
      INCOME_VERIFICATION_STATUS.STARTED,
      INCOME_VERIFICATION_STATUS.LINKED,
    ]

    return incomeVerificationStatuses.includes(status)
  }

  shouldRedirectToDocumentUpload(status) {
    const documentUploadStatuses = [
      INCOME_VERIFICATION_STATUS.SUBMITTED,
      INCOME_VERIFICATION_STATUS.READY,
    ]

    return documentUploadStatuses.includes(status)
  }

  shouldRedirectToReviewReportPage(rentalSubmission) {
    const isCreditCompletedOrRequestedStatuses = CREDIT_FOR_RENTER_VIEW_REPORT_STATUS.includes(
      rentalSubmission.credit_report?.status,
    )
    const shouldRedirectToReview =
      rentalSubmission.status === RENTAL_STATUS.REVIEW ||
      isCreditCompletedOrRequestedStatuses

    return rentalSubmission.isRenterViewReportFirst && shouldRedirectToReview
  }

  shouldRedirectToSubmittedPage(rentalSubmission) {
    const backNavigationStatuses = [RENTAL_STATUS.PROPERTY_REQUIRED]

    return backNavigationStatuses.includes(get(rentalSubmission, "status"))
  }

  markShouldCheckRedirectToFalse() {
    this.setState({ shouldCheckRedirect: false })
  }

  onPayAndSubmit = () => {
    const {
      actions,
      isSelectMultiShareOffer,
      isAllowMultiShareOffer,
    } = this.props
    // LNR-3649: temporary remove statsig event tracking due to quota limit
    // this.logStatSigEvent("apply_now::click submit reports")

    if (isAllowMultiShareOffer && isSelectMultiShareOffer === undefined) {
      actions.setIsShowRequireMultiShare(true)
      return
    }
    const isEligibleAndSelectMultiShareOffer =
      isAllowMultiShareOffer && isSelectMultiShareOffer
    tracker.trackEvent(PAYMENT_EVENT.clickSubmit, {
      ...needPayment("onPayAndSubmit"),
      select_multi_share: isEligibleAndSelectMultiShareOffer,
    })
    actions.getPayment({
      provider: "stripe",
      multishare: isEligibleAndSelectMultiShareOffer,
    })
  }
  onShowError = () => {
    this.setState({ showError: true })
  }

  isStepComplete = () => {
    const { rentalSubmission, status } = this.props
    const screeningOption = get(rentalSubmission, ["screeningOption"], {})
    const applicationSelect = get(screeningOption, "application", false) ? 2 : 0
    const creditSelect = get(screeningOption, "credit_report", false) ? 1 : 0
    const maxStep = applicationSelect + creditSelect
    const stepList = generateStepList(rentalSubmission, status)
    const isStepCompleted = stepList.filter(
      ({ status: stepStatus }) =>
        stepStatus === STATUS.READY || stepStatus === STATUS.RENTER_SIGNED,
    )
    if (isStepCompleted.length === maxStep && maxStep !== 0) {
      this.setState({ isStepCompleted: true })
      return true
    }
    this.setState({ isStepCompleted: false })
    return false
  }

  handleUploadDocument = () => {
    const { documents } = this.props
    tracker.trackEvent(DOCUMENT_UPLOAD_EVENT.ACCESS_DOCUMENT_UPLOAD, {
      purpose: getDocumentUploadPurpose(documents),
    })
  }

  // TODO: lnr-3112 clean up statsig log
  logStatSigEvent(event) {
    const { rentalSubmission, match } = this.props
    const screeningRequestId = get(match, "params.screeningRequestId", "")
    Statsig.logEvent(
      event,
      screeningRequestId,
      get(rentalSubmission, ["screeningOption"], {}),
    )
  }

  render() {
    const {
      match,
      property,
      agentProfile,
      rentalSubmission,
      actions,
      status,
      isPayAndSubmit,
      isSubmitDisable,
      loading,
      rentalGuide,
      multiShare,
      documents,
      isSelectMultiShareOffer,
      isAllowMultiShareOffer,
      isCentralizedSignPageGateEnabled,
    } = this.props
    const screeningRequestId = get(match, "params.screeningRequestId", "")
    const { showError, isStepCompleted } = this.state

    const { isMultiShareActive, multiShareExpiredAt } = multiShare
    const address = generateAddress(property)
    const screeningOption = rentalSubmission?.screeningOption
    const proOption = rentalSubmission?.proOption
    const isAppOnly = get(screeningOption, "credit_report") === false
    const isRenterPay = screeningOption?.payerType === USER_TYPE.RENTER
    const applicationType = screeningOption?.applicationType
    const price = get(rentalSubmission, "credit_report.total")
    const stepList = generateStepList(rentalSubmission, status)
    const totalFee = isMultiShareActive ? 0 : price
    const isShowApplicationFee = isMultiShareActive || isRenterPay
    const applicationFee = getApplicationFeeContent(screeningOption, proOption)
    const isApplicationExists = get(screeningOption, "application", false)

    const {
      isNextToDocumentStep,
      isShowDocumentStep,
      isProUserSelectOnlyIncome,
    } = getRedirectConditionParams(screeningOption, documents)

    const shouldShowAgentBadge = !isEmpty(get(agentProfile, "displayName"))

    if (agentProfile.link) {
      agentProfile.onClick = () => {
        window.open(agentProfile.link, "_blank", "noopener,noreferrer")
      }
    }

    const handleSelectMultiShareOfferOption = payload => {
      const multiShareSelectedOptionKey = getMultiShareSelectedOptionKey({
        rentalId: rentalSubmission.id,
        renterId: rentalSubmission.renter_id,
      })
      window.localStorage.setItem(multiShareSelectedOptionKey, payload)
      actions.setIsSelectMultiShare(payload)
    }

    const multiShareExpiredInDays =
      multiShareExpiredAt &&
      dateFns.differenceInDays(
        dateFns.startOfToday(),
        dateFns.parse(multiShareExpiredAt),
      )
    const isExpiredMultiShare = new Date(multiShareExpiredAt) <= new Date()
    const isShowMultiShareExpiredMessage =
      multiShareExpiredAt &&
      isExpiredMultiShare &&
      multiShareExpiredInDays < NUMBER_OF_DAYS_TO_DISPLAY_MULTI_SHARE_EXPIRED

    const onNext = () => {
      if (screeningOption.income) {
        if (this.props.bundleId === TU_BUNDLE_ID.ALL_PLUS_INCOME_ESTIMATE) {
          this.props.actions.redirect(ROUTE.DOCUMENT_UPLOAD, true)
        } else {
          actions.redirect(ROUTE.INCOME_VERIFICATION, true)
        }
      } else {
        actions.redirect(ROUTE.DOCUMENT_UPLOAD, true)
      }
    }

    const onSubmit = () => {
      tracker.trackEvent(PAYMENT_EVENT.clickSubmit, {
        ...needPayment("onSubmit"),
        select_multi_share: "none",
      })
      // LNR-3649: temporary remove statsig event tracking due to quota limit
      // this.logStatSigEvent("apply_now::click submit reports")
      actions.submitRental({
        isNextToDocumentStep,
      })
    }

    const submitButtonProps = makeSubmitButtonProps({
      actions: {
        prepareEnvelopeAction: actions.prepareEnvelope,
        paymentAction: () => this.onPayAndSubmit(),
        submitAction: () => onSubmit(),
        nextAction: () => onNext(),
      },
      applicationType: isApplicationExists && applicationType,
      isPayAndSubmit,
      isNextToDocumentStep,
      isProUserSelectOnlyIncome,
      applicationStatus: status?.application,
      isRenterPay,
      screeningRequestId,
      isCentralizedSignPageGateEnabled,
    })

    return (
      <div>
        {showError && (
          <ErrorAlert
            closeable={false}
            error={{ message: message.paymentPaid }}
            warning
            haveContactInfo
          />
        )}
        <RentalGuideTitle>Submit Your Application</RentalGuideTitle>
        {shouldShowAgentBadge && (
          <RentalDetail
            price={price}
            address={address}
            isShowAgentBadge
            addressTextColor={COLOR.cyanBlue}
            agentProfile={agentProfile}
            isRenterPay={isRenterPay}
            isPaid={!isPayAndSubmit}
            margin="5px 0 0"
            mMargin="5px 0 30px"
          />
        )}
        <FlexContainer
          direction="row"
          mDirection="column"
          alignItems="baseline"
          mAlignItems="stretch"
          justify="flex-start"
          mGap="10px"
          margin="30px 0 10px"
          mMargin="30px 0 20px">
          <S24 margin="0">Steps</S24>
          <MobileHr />
        </FlexContainer>

        {stepList.map((step, i) => (
          <RentalStep
            key={step.step}
            {...step}
            index={i + 1}
            onClick={() => step.onClick({ actions })}
            screeningOption={screeningOption}
          />
        ))}
        {isShowDocumentStep && (
          <DocumentStep
            documents={documents}
            onUploadDocument={() => this.handleUploadDocument()}
          />
        )}
        {isShowMultiShareExpiredMessage && (
          <InfoMessagePanel testId="multishare-expired">
            <div>
              <Span weight={600}>
                {generateMultiShareMessage(multiShare, isAppOnly)}
              </Span>
            </div>
          </InfoMessagePanel>
        )}
        {isAllowMultiShareOffer && (
          <SpecialOfferStep
            handleAction={handleSelectMultiShareOfferOption}
            testId="multi-share"
            setIsShowError={actions.setIsShowRequireMultiShare}
            isSelectSpecialOffer={isSelectMultiShareOffer}
            isShowError={rentalGuide.isShowRequireMultiShareOffer}
            acceptOfferLabel={MULTI_SHARE_COPIES.optionYes}
            notAcceptOfferLabel={MULTI_SHARE_COPIES.optionNo}>
            <MultiShareOfferTitleContainer>
              <S18 style={{ lineHeight: "24px", margin: 0 }}>
                {MULTI_SHARE_COPIES.questionTitle1}
              </S18>
            </MultiShareOfferTitleContainer>
            <MultiShareOfferSubTitleContainer>
              {MULTI_SHARE_COPIES.questionSubtitle1}
            </MultiShareOfferSubTitleContainer>
          </SpecialOfferStep>
        )}
        {isStepCompleted && (
          <>
            <WrapperWording>
              <B14 size="12px" margin="10px 0 0 0" color="#333333">
                <SubmitTUWording
                  buttonName={isPayAndSubmit ? "Continue to payment" : "Submit"}
                />
              </B14>
            </WrapperWording>
            <Hr margin="20px 0 0 0" />
          </>
        )}
        {isMultiShareActive && (
          <InfoMessagePanel testId="multishare-activated">
            <div>
              <Span weight={600}>{generateMultiShareMessage(multiShare)}</Span>
            </div>
          </InfoMessagePanel>
        )}
        {isShowApplicationFee && (
          <AppFeeWithTooltip
            title={applicationFee.TITLE}
            tooltipContent={applicationFee.TOOLTIP}
            fee={totalFee}
            isSpecialOfferSelected={
              isAllowMultiShareOffer && isSelectMultiShareOffer
            }
            handleSelectSpecialOfferOption={handleSelectMultiShareOfferOption}
          />
        )}
        <SubmitButtonWrapper>
          <div name="submit-button">
            <BlueGradientButton
              {...submitButtonProps}
              small
              semiBold
              shadow
              hasIcon
              minWidth="100"
              mWidth="100%"
              disabled={isSubmitDisable}
              loading={loading.components && !loading.success}
              loaded={loading.success}
              margin={isShowApplicationFee ? "20px 0 30px 0" : "30px 0 30px 0"}
            />
          </div>
        </SubmitButtonWrapper>
      </div>
    )
  }
}

export const generatePurposeFromStatus = status => {
  switch (status) {
    case "created":
      return "start"
    case "edit":
      return "continue"
    case "ready":
      return "edit"
    default:
      return ""
  }
}

export const generateStepList = (rental, status = {}) => {
  const list = []

  if (get(rental, "screeningOption.application")) {
    let applicantRedirectPath = APPLICANT_PATH_FLOW.APPLICANT_INFO.current
    if (status.applicant === STATUS.EDIT && rental.continuePath) {
      applicantRedirectPath = rental.continuePath
    }

    list.push({
      step: RENTAL_STEP.APPLICANT,
      status:
        status.application === STATUS.RENTER_SIGNED
          ? STATUS.RENTER_SIGNED
          : status.applicant,
      onClick: ({ actions }) => {
        tracker.trackEvent(RENTAL_GUIDE_EVENT.accessApplicant, {
          purpose: generatePurposeFromStatus(status.applicant),
          template: rental.application.type,
          template_version: rental.application.version,
        })

        applicantRedirectPath = ROUTE.APPLICANT.BASE

        actions.redirect(applicantRedirectPath)
      },
    })
    list.push({
      step: RENTAL_STEP.APPLICATION,
      status: status.application,
      onClick: ({ actions }) => {
        tracker.trackEvent(RENTAL_GUIDE_EVENT.accessRental, {
          purpose: generatePurposeFromStatus(status.application),
          template: rental.application.type,
          template_version: rental.application.version,
        })
        actions.redirectRentalApp("")
      },
    })
  }
  if (get(rental, "screeningOption.credit_report")) {
    list.push({
      step: RENTAL_STEP.CREDIT_REPORT,
      status: status.creditReport,
      onClick: ({ actions }) => {
        tracker.trackEvent(RENTAL_GUIDE_EVENT.accessTransUnion, {
          purpose: generatePurposeFromStatus(status.creditReport),
        })
        actions.redirect(ROUTE.TU_SCREENING.BASE)
      },
    })
  }
  return list
}

// TODO: Clean up: remove HOC withFeatureFlag for `centralized_sign_page`
const withFeatureFlag = WrappedComponent => props => {
  const { value: isCentralizedSignPageGateEnabled } = useGate(
    CENTRALIZED_SIGN_PAGE,
  )

  return (
    <WrappedComponent
      {...props}
      isCentralizedSignPageGateEnabled={isCentralizedSignPageGateEnabled}
    />
  )
}

export default compose(
  track(),
  withSaga,
  withIncomeVerificationSaga,
  withTuScreeningSaga,
  withReducer,
  withIncomeVerificationReducer,
  withTuScreeningReducer,
  withConnect,
  withRouter,
)(withTracker(withFeatureFlag(GuidePage), tracker, RENTAL_PAGE_NAME.GUIDE))
