import { createSelector } from "reselect"
import get from "lodash/get"

import { initialState } from "./reducer"

const documentReducer = state => get(state.toJS(), "document", initialState)

export const selectDocument = createSelector(documentReducer, state => state)

export const selectIsSubmitting = createSelector(
  documentReducer,
  state => state.isSubmitting,
)

export const selectIsReplacingDocument = createSelector(
  documentReducer,
  state => state.isReplacingDocument,
)

export const selectIsLoading = createSelector(
  documentReducer,
  state => state.isLoading,
)

export const selectIsRenderIdUpload = createSelector(
  documentReducer,
  state => state.isRenderIdUpload,
)
