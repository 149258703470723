import React from "react"
import { Auth0Provider } from "@auth0/auth0-react"

import {
  AUTH_PROVIDER_DOMAIN,
  AUTH_APP_CLIENT_ID,
  AUTH_API_IDENTIFIER,
  AUTH_APP_SCOPE,
} from "env"
import { AuthWrapper } from "./auth-wrapper"

export const AuthProvider = ({ children }) => (
  <Auth0Provider
    domain={AUTH_PROVIDER_DOMAIN}
    clientId={AUTH_APP_CLIENT_ID}
    authorizationParams={{
      scope: AUTH_APP_SCOPE,
      audience: AUTH_API_IDENTIFIER,
    }}
    cacheLocation="localstorage"
    useRefreshTokens>
    <AuthWrapper>{children}</AuthWrapper>
  </Auth0Provider>
)
