import axios from "axios"
import get from "lodash/get"
import merge from "lodash/merge"
import isEmpty from "lodash/isEmpty"
import cloneDeep from "lodash/cloneDeep"
import { getLocalItem } from "@rentspree/cookie"
import { axiosResponse } from "@rentspree/axios"
import {
  API_URL,
  USER_API_URL,
  AGENT_REVIEW_API_BASE_URL,
  FILES_URL,
} from "../env"
import {
  handleApiErrors,
  handleAuthorization,
  handleApiErrorsByPass404,
} from "./handle-api-errors"

let requestInterceptorAdded = false

axios.interceptors.response.use(axiosResponse.response, error => {
  throw error.response
})
const apiInstance = axios.create({
  baseURL: API_URL,
})

const financialVerificationApiInstance = axios.create({
  baseURL: API_URL,
})

const userInstance = axios.create({
  baseURL: USER_API_URL,
})

const filesInstance = axios.create({
  baseURL: FILES_URL,
})

const documentApiInstance = axios.create({
  baseURL: `${API_URL}/api/v2/other-documents`,
})

const agentReviewApiInstance = axios.create({
  baseURL: `${API_URL}${AGENT_REVIEW_API_BASE_URL}`,
})

const timestampConfig = () => ({ params: { t: new Date().getTime() } })

const injectRequestInterceptor = ({ getAccessToken }) => {
  const requestInterceptor = async config => {
    const defaultConfig = {
      headers: {
        post: {
          "Content-Type": "application/json",
        },
      },
    }
    const configMerge = merge(cloneDeep(defaultConfig), config)
    const headers = get(configMerge, "headers", {})
    let accessToken
    // Auth0 Migration
    if (getAccessToken) {
      accessToken = await getAccessToken()
    } else {
      accessToken = get(getLocalItem("rentspreeUserToken"), "access_token")
    }
    if (!isEmpty(accessToken)) {
      headers.Authorization = `Bearer ${accessToken}`
    }
    return { ...configMerge, headers, ...timestampConfig() }
  }

  if (!requestInterceptorAdded) {
    apiInstance.interceptors.request.use(requestInterceptor)
    financialVerificationApiInstance.interceptors.request.use(
      requestInterceptor,
    )
    userInstance.interceptors.request.use(requestInterceptor)
    filesInstance.interceptors.request.use(requestInterceptor)
    documentApiInstance.interceptors.request.use(requestInterceptor)
    agentReviewApiInstance.interceptors.request.use(requestInterceptor)
    requestInterceptorAdded = true
  }
}

apiInstance.interceptors.response.use(axiosResponse.response, error =>
  handleAuthorization(error),
)

apiInstance.interceptors.response.use(r => r, error => handleApiErrors(error))

financialVerificationApiInstance.interceptors.response.use(
  axiosResponse.response,
  error => handleAuthorization(error),
)

financialVerificationApiInstance.interceptors.response.use(
  r => r,
  error => handleApiErrorsByPass404(error),
)

userInstance.interceptors.response.use(axiosResponse.response, error =>
  handleAuthorization(error),
)

userInstance.interceptors.response.use(r => r, error => handleApiErrors(error))

agentReviewApiInstance.interceptors.response.use(
  axiosResponse.response,
  error => handleAuthorization(error),
)

filesInstance.interceptors.response.use(axiosResponse.response, error =>
  handleAuthorization(error),
)

documentApiInstance.interceptors.response.use(axiosResponse.response, error =>
  handleAuthorization(error),
)

export {
  apiInstance,
  userInstance,
  agentReviewApiInstance,
  filesInstance,
  documentApiInstance,
  financialVerificationApiInstance,
  injectRequestInterceptor,
}
