/*
 *
 * rental constants
 *
 */
import _ from "lodash"
import { REX_SUBMIT_APPLICATION_NAME, REX_APPLY_CHECKLIST_ID } from "env"

export const GET_RENTAL_SUBMISSION_CALL = "GET_RENTAL_SUBMISSION_CALL"
export const GET_RENTAL_SUBMISSION_REQUEST = "GET_RENTAL_SUBMISSION_REQUEST"
export const GET_RENTAL_SUBMISSION_SUCCESS = "GET_RENTAL_SUBMISSION_SUCCESS"
export const GET_RENTAL_SUBMISSION_FAILED = "GET_RENTAL_SUBMISSION_FAILED"
export const GET_RENTAL_WITH_CO_APPLICANT_CALL =
  "GET_RENTAL_WITH_CO_APPLICANT_CALL"
export const GET_RENTAL_WITH_CO_APPLICANT_REQUEST =
  "GET_RENTAL_WITH_CO_APPLICANT_REQUEST"
export const GET_RENTAL_WITH_CO_APPLICANT_SUCCESS =
  "GET_RENTAL_WITH_CO_APPLICANT_SUCCESS"
export const GET_RENTAL_WITH_CO_APPLICANT_FAILED =
  "GET_RENTAL_WITH_CO_APPLICANT_FAILED"
export const SET_RENTAL_APP_CONTINUE_PATH = "SET_RENTAL_APP_CONTINUE_PATH"
export const SET_RENTAL_SPECIFICS_CONTINUE_PATH_FROM_AUTOSAVE =
  "SET_RENTAL_SPECIFICS_CONTINUE_PATH_FROM_AUTOSAVE"

export const GET_RENTAL_API_URL =
  "/api/v2/screening-requests/:screeningRequestId/rental-submissions/apply"
export const GET_RENTAL_WITH_CO_APPLICANT_API_URL =
  "/api/v2/rental-submissions/:rentalId"

export const MOVING_GET_CHECK_LIST_API_ENDPOINT =
  "/api/checklists/find-or-create"

export const TOGGLE_MOVING_TASK_CHECK_API_ENDPOINT =
  "/api/checklists/toggle-check"

export const CREATE_SUBMIT_APPLICATION_NOTIFICATION_API_ENDPOINT =
  "api/checklists/create-submit-application-notification"

export const getRentalSubmissionCall = payload => ({
  type: GET_RENTAL_SUBMISSION_CALL,
  payload,
})
export const getRentalSubmissionRequest = payload => ({
  type: GET_RENTAL_SUBMISSION_REQUEST,
  payload,
})
export const getRentalSubmissionSuccess = payload => ({
  type: GET_RENTAL_SUBMISSION_SUCCESS,
  payload,
})
export const getRentalSubmissionFailed = payload => ({
  type: GET_RENTAL_SUBMISSION_FAILED,
  payload,
})
export const getRentalWithCoApplicantCall = () => ({
  type: GET_RENTAL_WITH_CO_APPLICANT_CALL,
})
export const getRentalWithCoApplicantRequest = () => ({
  type: GET_RENTAL_WITH_CO_APPLICANT_REQUEST,
})
export const getRentalWithCoApplicantSuccess = (
  payload,
  isResumeAppEnabled,
) => ({
  type: GET_RENTAL_WITH_CO_APPLICANT_SUCCESS,
  payload,
  isResumeAppEnabled,
})
export const getRentalWithCoApplicantFailed = payload => ({
  type: GET_RENTAL_WITH_CO_APPLICANT_FAILED,
  payload,
})

export const RENTAL_STATUS = {
  NOT_INTERESTED: "not_interested",
  CREATED: "created",
  STARTED: "started",
  EDIT: "edit",
  REVIEW: "review",
  REQUESTED: "requested",
  READY: "ready",
  PROPERTY_REQUIRED: "property_required",
  SUBMITTED: "submitted",
}

export const CREDIT_REPORT_STATUS = {
  CREATED: "created",
  EXAM: "exam",
  ERROR: "error",
  STARTED: "started",
  READY: "ready",
  PAYMENT: "payment",
  PROCESSING_PAYMENT: "processing_payment",
  COMPLETED: "completed",
  SUBMITTED: "submitted",
  REQUESTED: "requested",
  OPENED: "opened",
}

export const CREDIT_FOR_RENTER_VIEW_REPORT_STATUS = [
  CREDIT_REPORT_STATUS.COMPLETED,
  CREDIT_REPORT_STATUS.REQUESTED,
]

export const APPLICATION_TYPE = {
  CAR_LRA: "car_lra",
  RENTSPREE: "rentspree",
}
export const APPLICATION_STATUS = {
  CREATED: "created",
  EDIT: "edit",
  READY: "ready",
  RENTER_SIGNED: "renter_signed",
  SUBMITTED: "submitted",
}

export const isInstruction = (status, oldVersion) =>
  oldVersion ||
  !status ||
  _.includes(
    [
      RENTAL_STATUS.NOT_INTERESTED,
      RENTAL_STATUS.CREATED,
      RENTAL_STATUS.REQUESTED,
    ],
    status,
  )

// must change
export const APPLY_CHECKLIST = {
  ID: REX_APPLY_CHECKLIST_ID || 1,
  TYPE: "Apply & Sign Lease",
}
export const SUBMIT_APPLICATION_NAME =
  REX_SUBMIT_APPLICATION_NAME || "Submit application"

export const CHECKLIST_NOTIFICATION_TYPE = {
  SUBMIT_APPLICATION: "submit-application",
}
export const RENTAL_FINISH_BUTTON_TEXT = {
  TO_DASHBOARD: "Take me home",
  APPLY_ANOTHER: "Apply for another property",
}

export const DAILY_APPLY_LIMIT_NUMBER = 5

export const NUMBER_OF_DAYS_TO_DISPLAY_MULTI_SHARE_EXPIRED = 90

export const PAYER_TYPE = {
  RENTER: "renter",
  LANDLORD: "landlord",
}
