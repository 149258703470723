import React, { useEffect } from "react"
import { compose } from "redux"
import { REQUEST_STATUS_TYPE } from "redux-middleware/constants"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"
import { Statsig, useGate } from "statsig-react"
import { RENTAL_STATUS } from "containers/rental-submission/constants"
import { CENTRALIZED_SIGN_PAGE } from "utils/feature-flag/constants"
import { withReducer, withSaga } from "../connect"
import { withConnect } from "./connect"
import { FullScreenLoading } from "../../loading"

export const AfterSignEnvelopePage = ({
  match,
  actions,
  rentalSubmission,
  afterSignStatus,
  isSigned,
  isLoadingPrepareEnvelope,
  envelope,
  loading,
}) => {
  const { userID } = Statsig.getCurrentUser()
  // Waiting for main loading
  // !userID || userID === "default-id" :: is a workaround to wait for StatSig readiness before useGate()
  if (loading.full || !userID || userID === "default-id") {
    return <></>
  }

  // TODO: Clean up: remove `centralized_sign_page` flag
  const { value: isCentralizedSignPageGateEnabled } = useGate(
    CENTRALIZED_SIGN_PAGE,
  )

  if (!isCentralizedSignPageGateEnabled) {
    actions.redirect(null, true)
    return <></>
  }

  useEffect(() => {
    if (isLoadingPrepareEnvelope === false && isEmpty(envelope)) {
      const envelopeId = get(match, "params.envelopeId", "")
      actions.getEnvelopeByIdCall(envelopeId)
    }
  }, [])

  useEffect(
    () => {
      if (!isLoadingPrepareEnvelope && !isEmpty(envelope)) {
        // #1 - Renter sign completed, proceed to handle after sign flow
        if (
          isSigned &&
          !isEmpty(rentalSubmission) &&
          afterSignStatus === REQUEST_STATUS_TYPE.INITIAL
        ) {
          // #1.1 - If rental-submission is submitted, fallback to rental-guide
          if (rentalSubmission.status === RENTAL_STATUS.SUBMITTED) {
            actions.redirect(null, true)
          } else {
            // #1.2 - Else handle after sign flow
            const isNextToDocumentStep =
              rentalSubmission.screeningOption?.income
            actions.handleAfterEnvelopeSign({
              isNextToDocumentStep,
            })
          }
        } else if (!isSigned) {
          // #2 - Renter not sign, redirect them back to rental-guide
          actions.redirect(null, true)
        }
      }
    },
    [isLoadingPrepareEnvelope, envelope, rentalSubmission, afterSignStatus],
  )

  return (
    <FullScreenLoading
      id="prepareEnvelopeLoading"
      text="Preparing document(s) for you... This may take up to 30 seconds"
    />
  )
}

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(AfterSignEnvelopePage)
