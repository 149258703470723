import generateApiCall from "utils/generate-api-call"
import {
  GET_DRAFT_DOCUMENT_CALL,
  GET_DRAFT_DOCUMENT_REQUEST,
  GET_DRAFT_DOCUMENT_SUCCESS,
  GET_DRAFT_DOCUMENT_FAILED,
  UPLOAD_AND_SAVE_DOCUMENT_FILE_CALL,
  UPLOAD_DOCUMENT_FILE_REQUEST,
  UPLOAD_DOCUMENT_FILE_SUCCESS,
  UPLOAD_DOCUMENT_FILE_FAILED,
  SAVE_UPLOADED_DOCUMENT_FILE_REQUEST,
  SAVE_UPLOADED_DOCUMENT_FILE_SUCCESS,
  SAVE_UPLOADED_DOCUMENT_FILE_FAILED,
  SET_UPLOADING_FILE_PROGRESS,
  DELETE_DOCUMENT_FILE_SUCCESS,
  DELETE_DOCUMENT_FILE_REQUEST,
  DELETE_DOCUMENT_FILE_FAILED,
  DELETE_DOCUMENT_FILE_CALL,
  REJECT_SELECTED_FILES,
  CLEAR_FILE_REJECTED_MESSAGES,
  GET_DOCUMENT_SET_CALL,
  GET_DOCUMENT_SET_REQUEST,
  GET_DOCUMENT_SET_SUCCESS,
  GET_DOCUMENT_SET_FAILED,
  CLEAR_IN_PROGRESS_FILES,
  SUBMIT_RENTAL_SUBMISSION_CALL,
  SUBMIT_RENTAL_SUBMISSION_REQUEST,
  SUBMIT_RENTAL_SUBMISSION_SUCCESS,
  SUBMIT_RENTAL_SUBMISSION_FAILED,
  REPLACE_DOCUMENT_CALL,
  REPLACE_DOCUMENT_REQUEST,
  REPLACE_DOCUMENT_SUCCESS,
  REPLACE_DOCUMENT_FAILED,
  LANDING_DOCUMENT_UPLOAD_CALL,
  LANDING_DOCUMENT_UPLOAD_REQUEST,
  LANDING_DOCUMENT_UPLOAD_SUCCESS,
  LANDING_DOCUMENT_UPLOAD_FAILED,
} from "./constants"

export const getDraftDocument = payload => ({
  type: GET_DRAFT_DOCUMENT_CALL,
  payload,
})

export const getDraftDocumentApiState = generateApiCall([
  GET_DRAFT_DOCUMENT_REQUEST,
  GET_DRAFT_DOCUMENT_SUCCESS,
  GET_DRAFT_DOCUMENT_FAILED,
])

export const cancelUnlistedDocuments = payload => ({
  type: REPLACE_DOCUMENT_CALL,
  payload,
})

export const cancelUnlistedDocumentsApiState = generateApiCall([
  REPLACE_DOCUMENT_REQUEST,
  REPLACE_DOCUMENT_SUCCESS,
  REPLACE_DOCUMENT_FAILED,
])

export const uploadAndSaveDocumentFile = payload => ({
  type: UPLOAD_AND_SAVE_DOCUMENT_FILE_CALL,
  payload,
})

export const uploadDocumentFileApiState = generateApiCall([
  UPLOAD_DOCUMENT_FILE_REQUEST,
  UPLOAD_DOCUMENT_FILE_SUCCESS,
  UPLOAD_DOCUMENT_FILE_FAILED,
])

export const saveUploadedDocumentFileApiState = generateApiCall([
  SAVE_UPLOADED_DOCUMENT_FILE_REQUEST,
  SAVE_UPLOADED_DOCUMENT_FILE_SUCCESS,
  SAVE_UPLOADED_DOCUMENT_FILE_FAILED,
])

export const setUploadingFileProgress = payload => ({
  type: SET_UPLOADING_FILE_PROGRESS,
  payload,
})

export const deleteDocumentFile = payload => ({
  type: DELETE_DOCUMENT_FILE_CALL,
  payload,
})

export const deleteDocumentFileApiState = generateApiCall([
  DELETE_DOCUMENT_FILE_REQUEST,
  DELETE_DOCUMENT_FILE_SUCCESS,
  DELETE_DOCUMENT_FILE_FAILED,
])

export const rejectSelectedFiles = payload => ({
  type: REJECT_SELECTED_FILES,
  payload,
})

export const clearFileRejectedMessages = payload => ({
  type: CLEAR_FILE_REJECTED_MESSAGES,
  payload,
})

export const clearInProgressFiles = () => ({
  type: CLEAR_IN_PROGRESS_FILES,
})

export const getDocumentSet = payload => ({
  type: GET_DOCUMENT_SET_CALL,
  payload,
})

export const getDocumentSetApiState = generateApiCall([
  GET_DOCUMENT_SET_REQUEST,
  GET_DOCUMENT_SET_SUCCESS,
  GET_DOCUMENT_SET_FAILED,
])

export const submitRentalSubmission = payload => ({
  type: SUBMIT_RENTAL_SUBMISSION_CALL,
  payload,
})

export const submitRentalSubmissionApiState = generateApiCall([
  SUBMIT_RENTAL_SUBMISSION_REQUEST,
  SUBMIT_RENTAL_SUBMISSION_SUCCESS,
  SUBMIT_RENTAL_SUBMISSION_FAILED,
])

export const landingDocumentUpload = payload => ({
  type: LANDING_DOCUMENT_UPLOAD_CALL,
  payload,
})

export const landingDocumentUploadState = generateApiCall([
  LANDING_DOCUMENT_UPLOAD_REQUEST,
  LANDING_DOCUMENT_UPLOAD_SUCCESS,
  LANDING_DOCUMENT_UPLOAD_FAILED,
])
