import React, { useEffect } from "react"
import { compose } from "redux"
import { withRouter } from "react-router-dom"
import { getLocalItem } from "@rentspree/cookie"
import { query } from "@rentspree/path"
import _ from "lodash"

import { STORAGE } from "constants/cookie"
import { ROUTE } from "containers/router/constants"
import { StyledWrapper } from "components/layout"
import { FullScreenLoading } from "containers/loading"
import { LISTING_PARTNER } from "utils/partner-consts"
import { USER_TYPE } from "constants/user"
import { useDispatch } from "react-redux"
import { setIsResumeAppEnabledFeatureFlag } from "containers/feature-flags/actions"
import useAuth from "hooks/use-auth"

import { withConnect, withSaga, withReducer } from "./connect"
import { withFeatureFlagsReducer } from "../feature-flags/connect"

const v1RegexPath = new RegExp(ROUTE.APPLICATION.BASE, "i")
const v2RegexPath = new RegExp(ROUTE.APPLICATION_V2.BASE, "i")

export const Wrapper = props => {
  const {
    children,
    actions,
    loading,
    subdomain,
    match,
    location,
    history,
    isAllowNewQuestionUser,
  } = props
  const {
    getProfile,
    getCurrentRental,
    getScreeningRequest,
    verifyMultiShareCall,
  } = actions

  const { isAuthenticated } = useAuth()

  const saveAllField = screeningRequestId => {
    const data = query.parse(_.get(location, "search", ""))
    actions.saveAllField({
      ...data,
      screeningRequestId,
    })
  }
  const screeningRequestId = _.get(match, "params.screeningRequestId", "")
  saveAllField(screeningRequestId)
  useEffect(() => {
    // Auth0 Migration
    if (isAuthenticated || getLocalItem(STORAGE.USER_TOKEN)) {
      getProfile()
      verifyMultiShareCall()
      getCurrentRental(screeningRequestId)
      getScreeningRequest(screeningRequestId)
    }
  }, [])

  // TODO: Clean up QW_TS_RESUME_APPLICATION
  const isResumeAppEnabled = false
  const isResumeAppEnabledReady = true

  const dispatch = useDispatch()

  useEffect(
    () => {
      if (isResumeAppEnabledReady) {
        dispatch(setIsResumeAppEnabledFeatureFlag(isResumeAppEnabled))
      }
    },
    [isResumeAppEnabledReady],
  )

  useEffect(
    () => {
      const pathName = location?.pathname
      const isV2Path = v2RegexPath.test(pathName)
      const isV1Path = v1RegexPath.test(pathName) && !isV2Path

      if (isAllowNewQuestionUser && isV1Path) {
        const v2Path = pathName.replace(
          ROUTE.APPLICATION.BASE,
          ROUTE.APPLICATION_V2.BASE,
        )
        history.replace(v2Path)
      } else if (isV2Path && !isAllowNewQuestionUser) {
        const v1Path = pathName.replace(
          ROUTE.APPLICATION_V2.BASE,
          ROUTE.APPLICATION.BASE,
        )
        history.replace(v1Path)
      }
    },
    [location, isAllowNewQuestionUser],
  )

  // Auth0 Migration
  if (isAuthenticated || getLocalItem(STORAGE.USER_TOKEN)) {
    return (
      <>
        {_.get(loading, "full", false) && <FullScreenLoading />}
        <StyledWrapper hide={loading.full}>{children}</StyledWrapper>
      </>
    )
  }

  if (subdomain === LISTING_PARTNER.RENTALS) {
    actions.redirectAuthWeb(
      ROUTE.RENTER_OAUTH,
      { userType: USER_TYPE.APPLICANT },
      LISTING_PARTNER.RENTALS,
    )
  }
  actions.redirect(`${ROUTE.GUIDE.LANDING}${ROUTE.APPLY_NOW_LANDING}`)

  return null
}

export default compose(
  withReducer,
  withFeatureFlagsReducer,
  withSaga,
  withConnect,
  withRouter,
)(Wrapper)
